import userDetails from './user-details.html'
import Api from './api.js'
import formats from './models/formats'
import roles from './models/roles'
import manageUserForm from './manage-user-form'
import casesAssignedToUser from './CasesAssignedToUser.vue';
import Vue from 'vue'

export default {
    template: userDetails,
    components: {
      manageUserForm,
      casesAssignedToUser
    },
    props: {
      userPermissions: { type: Object },
    },
    data () {
      return {
        bus: new Vue(),
        tags: [],
        users: [],
        userErrors: [],
        userForm: {},
        tagValue: '',
        newTagName: '',
        homeOfficeAdmin: false,
        caseAdmin: false,
        userAdmin: false,
        reportsAdmin: false,
        userIsHomeOfficeAdmin: false,
        showUserForm: false
      }
    },
    methods: {
      getUser(userId) {
        Api.getUserByUserId(userId)
        .then(response => {
          this.userForm = response;
          this.showUserForm = true;
          this.userRoleHandler(this.userForm.roles);
        });
      },
      userRoleHandler(roles) {
        roles.forEach(role => {
          this.updateUserRoles(role.name)
        });
      },
      updateUserRoles(role, status = true) {
        switch(role) {
          case "HomeOfficeAdmin":
            this.userIsHomeOfficeAdmin = status;
          case "UserAdmin":
            this.userAdmin = status;
        }
      },
      finishEditUser() {
        // User is saved message
      },
      getAllTags() {
        return Api.searchTags()
        .then(response => {
          this.tags = formats.alphebetize(this.mapTags(response), 'text');
        });
      },
      mapTags(response) {
        return response.map(tag => {return {value:tag.id, text:tag.name}});
      },
      // Sending event to Cases assigned component to get updated assigned cases
      handleUserUpdated() {
        this.bus.$emit('userUpdated')
      },
      handleRoleUpdate(userRoles = {}) {
        for(const role in userRoles) {
          this.updateUserRoles(role, userRoles[role]);
        }
      }
    },
    watch: {
      homeOfficeAdmin: function() {
        this.caseAdmin = this.homeOfficeAdmin;
        this.userAdmin = this.homeOfficeAdmin;
        this.reportsAdmin = this.homeOfficeAdmin;
      },
    },
    created() {
      if(this.$route.params.id) {
        this.getUser(this.$route.params.id);
      }

      this.getAllTags();
    }
}