import reportsTemplate from './reports.html'
import Api from '../api.js'
import sorting from '../models/sorting'
import formats from '../models/formats'
import rowsPerPageOptions from '../models/rows-per-page-options'
import colorDefaults from '../models/color-defaults'
import enrollmentsTable from '../enrollments-table'

import {Report} from "./report-models.js"
import ReportFiltersComponent from "./report-filters"

export default {
  name: "ReportPage",
  template: reportsTemplate,
  components: {
    'filter-editor': ReportFiltersComponent,
    enrollmentsTable,
    sorting,
  },
  props: [
    "tab",
    "userPermissions"
  ],
  data: function() {
    return {
      // Reports user can see
      reports: [],

      // Cases user can see
      cases: [],

      // Currently selected report
      selectedReport: null,
      isEditingReport: false,

      isLoading: true,
      rowsPerPage: null,
      // Defaults for table
      tableProps: {
        sortBy: 'time',
        sortDesc: true,
        tableIsLoading: false,
        currentPage: 1,
        rowsPerPage: 25,
        totalCount: 0,
      },

      // Modals, etc
      newReportName: "",
      showNewReportModal: false,
      reportErrors: [],
      showAreYouSureModal: false,
      tableIsLoading: false,
      enrollments: [],
      colorDefaults: colorDefaults,
      isGeneratingReportDownload: false,
    };
  },
  created() {
    let rowsPerPage = parseInt(localStorage.rowsPerPage);
    this.rowsPerPage = rowsPerPage && rowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : this.tableProps.rowsPerPage;

    let reportPromise = this.searchReports();
    let casesPromise = this.fetchCases();
    Promise.all([casesPromise, reportPromise]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    fetchCases() {
      return Api.searchCases()
      .then(response => {
        this.cases = response;
      });
    },
    searchReports() {
      return Api.searchReports()
      .then(response => {
        this.reports = response.map((r) => new Report(r));

      });
    },
    openNewReportModal() {
      this.newReportName = null;
      this.showNewReportModal = true;
    },
    createReport() {
      Api.createReport({name: this.newReportName})
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.reportErrors = response.errors;
        } else {
          this.reportErrors = [];
          this.showNewReportModal = false;
          let newReport = new Report(response);
          this.reports.push(newReport);
          this.selectedReport = newReport;
          this.isEditingReport = true;
        }
      });
    },
    handleApplyAndSave(report) {
      this.handleApply(report);
      this.saveReport(report);
    },
    handleApply(report) {
      this.searchEnrollmentReports(report);
    },
    saveReport(report) {
      this.reportErrors = [];

      // if (this.validateNumberInputs()) {
      //   this.reportErrors.push({message: 'Invalid number for Last Days.'});
      //   return;
      // }

      this.updateReportSuccess = null;

      Api.updateReport(report.serialize())
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.reportErrors = response.errors;
        } else {

          this.selectedReport.name = report.name;
          this.selectedReport.filters = report.filters;

          this.reportErrors = [];
          this.updateReportSuccess = true;
          this.isEditingReport = false;
        }
      });
    },
    handleDeleteReport() {
      this.showAreYouSureModal = true;
    },
    deleteReport() {
      this.reportErrors = [];
      Api.deleteReport(this.selectedReport.id)
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.reportErrors = response.errors;
        } else {
          // Remove the report from the list
          this.reports = this.reports.filter(r => r.id !== this.selectedReport.id);

          // Reset
          this.selectedReport = null;
          this.showAreYouSureModal = false;
        }
      });
    },
    refreshSelectedReport() {
      this.searchEnrollmentReports(this.selectedReport);
    },

    searchEnrollmentReports(report) {
      this.tableProps.tableIsLoading = true;
      this.enrollments = [];
      let data = {
        page: this.tableProps.currentPage,
        sort_column: this.tableProps.sortBy,
        sort_direction: this.tableProps.sortDesc ? 'DESC' : 'ASC',
        report_filters: report.serializeFilters(),
        rows_per_page: this.tableProps.rowsPerPage
      };

      Api.searchEnrollmentReports(data)
      .then(response => {
        this.tableProps.totalCount = response.total_count;
        this.enrollments = response.rows;

        this.tableProps.tableIsLoading = false;
      });
    },

    downloadReport() {
      let data = {
        report_filters: this.selectedReport.serializeFilters(),
        search_format: 'csv'
      };

      this.isGeneratingReportDownload = true;

      Api.startGeneratingEnrollmentReportsCsv(data)
        .then(response => {
          // @TODO this basic code to handle download generation responses is duped in three separate places.
          //   Maybe consolidate -- EF 2021-08-17
          if (response.errors && response.errors.length > 0) {
            console.error(response.errors);
            alert("Unexpected error starting export.");
          } else {
            let msg = 'Download generating. You will receive an email shortly.'
            if (response && response.msg) {
              msg = response.msg
            }
            alert(msg);
            this.isGeneratingReportDownload = false;
          }
        }).finally(() => {
        this.isGeneratingReportDownload = false;
      });
    },
  },
  computed: {
    reportOptions() {
      let options =  this.reports.map(r => {
        return {text: r.name, value: r};
      });
      formats.alphebetize(options, 'text');
      return [{"text": "(Select Report)", "value": null}].concat(options);
    }
  },
  watch: {
    selectedReport() {
      if (this.selectedReport == null) {
        return;
      }
      // Update enrollments table when the report changes.
      this.refreshSelectedReport();
    }
  }
};
