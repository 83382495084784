import dashboard from './enrollments.html'
import setupcase from './case_setup/setup-case'
import caseDetailStatsWrap from './cases/case-detail-stats-wrap.vue'
import managecensus from './census/manage-census'
import newEnrollmentModal from './new-enrollment-modal'
import agentInbox from './agent-inbox'
import Api from './api.js'
import states from './models/states'
import getEnrollmentTableFields from './models/enrollments-table-fields'
import sorting from './models/sorting'
import formats from './models/formats'
import rowsPerPageOptions from './models/rows-per-page-options'
import bus from "./bus"
import Vue from 'vue'
import ShowNavigation from "./nav/ShowNavigation";
import {siteConfig, terminology} from "./app";
import PdfModal from "./PdfModal";
import modalListenerMixin from './mixins/modalListenerMixin.js'

const INBOX_CASE_ID = -99999;
const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;


export default {
    name: "Enrollments",
    template: dashboard,
    components: {
      setupcase,
      sorting,
      newEnrollmentModal,
      agentInbox,
      managecensus,
      caseDetailStatsWrap,
      ShowNavigation,
      PdfModal,
    },
    mixins: [modalListenerMixin],
    props: {
      tab: Object,
      userPermissions: { type: Object },
    },
    data () {
      return {
        fields: getEnrollmentTableFields(),
        enrollments: [],
        cases: [],
        caseErrors: [],
        enrollmentFilterOptions: [],
        rowsPerPageOptions: rowsPerPageOptions,
        caseSearch: '',
        currentPage: 1,
        rowsPerPage: 25,
        showEnrollModal: false,
        showNewCaseModal: false,
        enrollmentFilterValue: '',
        casesFilterOptions: [],
        caseFilterValue: null,
        statesOptions: states,
        newEnrollment: {
          caseId: null,
          city: null,
          state: null,
          ssn: null,
        },
        tabs: [],
        allEnrollments: [],
        caseForm: {group_name: ''},
        areYouSureCaseId: '',
        defaultSortColumn: 'time',
        tags: [],
        users: [],
        products: [],
        activeTabIndex: 0,
        isLoading: true,
        tableIsLoading: true,
        showUnsavedTabChangesModal: false,
        showSavingModal: false,
        agentInboxTotalCount: 0,
        INBOX_CASE_ID: INBOX_CASE_ID,
        isGeneratingCaseDownload: false,
        siteConfig: siteConfig,

        showPDFModal: false,
        viewingEnrollmentId: null,
      }
    },
    methods: {
      searchEnrollments(params) {
        Object.assign(params, {
          rows_per_page: this.rowsPerPage,
          page: this.currentPage
        });
        return Api.searchEnrollments(params)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            return response;
          }
        });
      },
      createCase() {
        Api.createCase(this.caseForm)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.caseErrors = response.errors;
          } else {
            this.caseErrors = [];
            Object.assign(this.caseForm, response);
            this.cases.push(response);
            this.casesFilterOptions = this.mapCases();
            this.caseFilterValue = response.id;

            if (!this.userPermissions.isHomeOfficeAdmin()) {
              this.addUserToCase(response.id).then(() => {
                this.caseFilterChange(response.id, true);
                this.showNewCaseModal = false;
              });
            } else {
              this.caseFilterChange(response.id, true);
              this.showNewCaseModal = false;
            }
          }
        });
      },
      addUserToCase(caseId) {
        let users = [{
          id: this.userPermissions.getUserId(),
          restricted_to_own_enrollments: false
        }];
        return Api.addUsersToCase(caseId, {users:users});
      },
      updateCase(tab) {
        this.casesFilterOptions = this.mapCases();
      },
      removeCase(caseId) {
        this.cases = this.cases.filter(function(c) {return c.id !== caseId});
        this.casesFilterOptions = this.mapCases();
        this.tabs = this.tabs.filter(function(t) {return t.case.id !== caseId});
        this.caseFilterValue = null;
        this.activeTabIndex = 0;
      },
      startGeneratingCaseDownload() {
        let tab = this.activeTab;
        let params = {
          case_id: tab.case.id ? tab.case.id : '',
          search_type: tab.filters.enrollmentFilterValue,
          search_text: tab.filters.caseSearch,
          search_format: 'csv'
        };

        this.isGeneratingCaseDownload = true;

        Api.startGeneratingEnrollmentCsv(params)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            console.error(response.errors);
            alert("Unexpected error starting export.");
          } else {
            let msg = 'Download generating. You will receive an email shortly.'
            if (response && response.msg) {
              msg = response.msg
            }
            alert(msg);
            this.isGeneratingCaseDownload = false;  // @todo this might not be necessary if done in .finally()
          }
        }).finally(() => {
          this.isGeneratingCaseDownload = false;
        });
      },
      showCaseSetup(tab) {
        tab.showCaseSection = true;

        tab.isCensusShowing = false;
        tab.isStatsShowing = false;
      },
      manageCensus() {
        const activeTab = this.tabs[this.activeTabIndex];
        if (activeTab.isCensusShowing) {
          activeTab.isCensusShowing = false;
        } else {
          activeTab.isCensusShowing = true;
          activeTab.showCaseSection = false;
          activeTab.isStatsShowing = false;
        }
      },
      viewStats() {
        const activeTab = this.tabs[this.activeTabIndex];
        if (activeTab.isStatsShowing) {
          activeTab.isStatsShowing = false;
        } else {
          activeTab.isStatsShowing = true;

          activeTab.showCaseSection = false;
          activeTab.isCensusShowing = false;
        }
      },
      searchCases() {
        return Api.searchCases()
          .then(response => {
            this.cases = response;
            this.casesFilterOptions = this.mapCases();
            this.caseFilterValue = null;
          });
      },
      mapCases() {
        let options = this.cases.map(c => {
          return {text: c.group_name, value: c.id};
        });
        options = formats.alphebetize(options, 'text');
        options.unshift({text: `Select ${this.$root.terminology.formatCase({isTitle: true})}`, value: null});
        return options;
      },
      searchTypes() {
        return Api.searchTypes()
        .then(response => {
          this.enrollmentFilterOptions = this.mapTypes(response);
        });
      },
      mapTypes(types) {
        let options = types.map(v => {
          let text;
          if (v === 'applicant') {
            text = 'Insured';
          } else if (v === 'case') {
            text = terminology.formatCase({isTitle: true})
          } else {
            // Capitalize
            text = `${v[0].toUpperCase()}${v.slice(1)}`;
          }
          return {
            text: text,
            value: v
          };
        });
        this.enrollmentFilterValue = options[0].value;
        return options;
      },
      removeUnwantedTabs() {
        this.tabs = this.tabs.filter(tab => {
          // Check if tab has no tab.case.id or if tab.case.id is equal to INBOX_CASE_ID
          return !tab.case.id || tab.case.id === INBOX_CASE_ID;
        });
      },
      caseFilterChange(caseId, showSettings) {
        let openCaseTab = this.tabs.find(c => {return c.case.id === caseId});

        if(openCaseTab === undefined) {
          let selectedCase = this.cases.find(c => c.id === caseId);
          let newTab = this.initNewTab(selectedCase);
          openCaseTab = newTab;
          this.removeUnwantedTabs();
          this.tabs.push(newTab);
        }

        // Wait until the current Vue events have flushed and UI is updated, then switch to the new tab.
        window.setTimeout(() => {

          this.setActiveTab(openCaseTab);

          // Reset the select dropdown to "Select Case"
          this.caseFilterValue = null;

          if (showSettings) {
            openCaseTab.showCaseSection = true;
          }

        }, 0);
      },
      searchCaseEnrollments() {
        this.tableIsLoading = true;
        let tab = this.activeTab;
        if (!tab || tab.case.id === INBOX_CASE_ID) {
          return;
        }
        tab.enrollments = [];
        let params = {
          case_id: tab.case.id ? tab.case.id : '',
          search_type: tab.filters.enrollmentFilterValue,
          search_text: tab.filters.caseSearch,
          //page: tab.page,
          sort_column: tab.sortBy,
          sort_direction: tab.sortDesc ? 'DESC' : 'ASC'
        };

        return this.searchEnrollments(params)
        .then(response => {
          tab.totalCount = response.total_count;
          tab.enrollments = response.rows;

          if(tab.case.group_name === 'Pending') {
            tab.enrollments = tab.enrollments.filter(e => {return e.status === 'pending'});
          }

          this.tableIsLoading = false;
        });
      },
      refreshAgentInbox() {
        let tab = this.activeTab;
        if (!tab) {
          return;
        }
        if (tab.component === 'agent-inbox') {
          bus.$emit('refreshAgentInbox');
        }
      },
      initializeTabs() {

        let paramId;

        // special handling if case_id is 'inbox'
        if (this.$route.params.case_id === 'inbox') {
          paramId = INBOX_CASE_ID;
        }

        this.tabs = [
          this.initNewTab({group_name: 'All'}),
          this.initAgentInboxTab()
        ];

        if (paramId === INBOX_CASE_ID) {
          this.tabs[1].isActive = true;
          window.setTimeout(() => {
            this.setActiveTab(this.tabs[1]);
          }, 0);
        }
        Vue.nextTick(()=> {
          this.searchCaseEnrollments();
        });
      },
      initNewTab(fcase) {
        let defaultFilters = {
          enrollmentFilterValue: this.enrollmentFilterOptions[0].value,
          caseSearch: '',
        };

        return {
          component: "enrollments",
          class: "",
          case: fcase,
          filters: defaultFilters,
          enrollments: [],
          sortBy: this.defaultSortColumn,
          sortDesc: true,
          showCaseSection: false,
          isCensusShowing: false,
          isStatsShowing: false,
          totalCount: null,
        };
      },
      initAgentInboxTab() {
        return {
          component: "agent-inbox",
          class: "agent-inbox",
          case: {group_name: 'Inbox', id: INBOX_CASE_ID},  // used for label in tab
          filters: {
            enrollmentFilterValue: this.enrollmentFilterOptions[0].value,  // not used
            caseSearch: '',  // not used
          },
          enrollments: [],  // not used
          enrollmentSessions: [],
          sortBy: this.defaultSortColumn,
          sortDesc: true,
          showCaseSection: false,   // not used
          isCensusShowing: false,
          isStatsShowing: false,
          totalCount: null,
        }
      },
      openNewCaseModal() {
        this.caseErrors = [];
        this.caseForm = {
          group_name: '',
          enrollment_open: true,
          premium_mode: 'monthly'
        };
        this.showNewCaseModal = true;
      },
      sortChanged(header) {
        if(header.sortBy) {
          this.activeTab.sortBy = header.sortBy;
          this.activeTab.sortDesc = header.sortDesc;
          this.searchCaseEnrollments();
        }
      },
      getAllProducts() {
        return Api.searchProducts()
        .then(response => {
          // sort by name
          response = response.sort(
            function compare(p1, p2) {
              if (p1['name'] < p2['name']) {
                return -1;
              }
              if (p1['name'] > p2['name']) {
                return 1;
              }
              return 0;
            });
          return this.products = this.mapMultiSelect(response);
        });
      },
      getAllTags() {
        return Api.searchTags()
        .then(response => {
          this.tags = this.mapMultiSelect(response);
        });
      },
      mapMultiSelect(response) {
        return response.map(r => {return {value:r.id, text:r.name}});
      },
      searchUsers() {
        Api.searchUsers()
        .then(response => {
          this.users = response.rows.map(r => {
            return {
              text: (r.first_name+' '+r.last_name),
              value: r.id,
              restrict: false,
              isApiUser: r.is_api_user,
            }
          });
        });
      },
      openNewEnroll(tab) {
        if(localStorage.enrollmentState && localStorage.enrollmentCity) {
          this.newEnrollment = setNewEnrollment((tab.case.id ? tab.case.id : null), localStorage.enrollmentCity, localStorage.enrollmentState);
        } else {
          if (tab) {
            this.newEnrollment = setNewEnrollment(tab.case.id, tab.case.situs_city, tab.case.situs_state);
          } else {
            this.newEnrollment = setNewEnrollment();
          }
        }

        this.showEnrollModal = !this.showEnrollModal;
        function setNewEnrollment(caseId=null, city, state) {
          return {
            caseId: caseId,
            city: city,
            state: state,
            ssn: null
          }
        }
      },
      closeCaseFilter(index) {
        // Right now, this allows modified case tabs to be closed without saving changes.

        //if (index === this.activeTabIndex && this.activeTab.showCaseSection && this.checkUserChanges()) {
        //  this.showUnsavedTabChangesModal
        //} else {
          this.tabs.splice(index, 1);
        //}
      },
      setActiveTab(tab) {
        this.activeTabIndex = this.tabs.indexOf(tab);
      },
      tabClick(index) {
        this.switchTab(this.activeTab.case.id);
      },
      switchTab(caseId) {

      },
      viewPDF(enrollmentId) {
        this.viewingEnrollmentId = enrollmentId;
        this.showPDFModal = true;
      },
      enrollmentClick(record) {
        // Census will have an applicant id on the record.
        if (record.status === 'census') {
          this.$router.push('/census-details/'+record.id);
        } else {
          this.$router.push({name: 'enrollmentDetails', params: {id: record.id}});
        }
      },
      loadSavedTabs() {
        let savedTabs = JSON.parse(localStorage.savedTabs);

        let handleCaseIdParam = () => {
          let paramId;
          let caseIdExistsInUserCases;

          // special handling if case_id is 'inbox'
          if (this.$route.params.case_id === 'inbox') {
            paramId = INBOX_CASE_ID;
            caseIdExistsInUserCases = true;
          } else {
            paramId = parseInt(this.$route.params.case_id);
            caseIdExistsInUserCases = this.cases.filter(c => { return c.id === paramId }).length > 0;
          }

          let caseIsAlreadyInSavedTabs = savedTabs.findIndex(t => t.caseId === paramId) > -1;

          if(caseIdExistsInUserCases) {
            savedTabs.forEach(t => {
              t.isActive = false;
            });
            if(caseIsAlreadyInSavedTabs) {
              let activeTabWithOpenCaseSetup = savedTabs.filter(t => { return t.caseId === paramId })[0];
              activeTabWithOpenCaseSetup.isActive = true;
              activeTabWithOpenCaseSetup.showCaseSection = false;
            } else {
              savedTabs.push({
                caseId: paramId,
                showCaseSection: false,
                isActive: true
              });
            }
          }
          return paramId;
        };

        let initAllTab = () => {
          let savedAllTab = savedTabs.filter(t => !t.caseId)[0];
          let newAllTab = this.initNewTab({group_name: 'All'});
          this.tabs.push(newAllTab);
          if(savedAllTab.isActive) {
            Vue.nextTick(()=> {
              this.searchCaseEnrollments();
            });
          }
          savedTabs = savedTabs.filter(t => t.caseId);
        };

        let initAgentTab = () => {
          let agentInboxTab = this.initAgentInboxTab();
          if (passedParamId === INBOX_CASE_ID) {
            agentInboxTab.isActive = true;
          }
          this.tabs.push(agentInboxTab);
          if(agentInboxTab.isActive) {
            window.setTimeout(() => {
              this.setActiveTab(agentInboxTab);
            }, 0);
          }
        };

        const passedParamId = handleCaseIdParam();
        initAllTab();
        initAgentTab();

        this.isLoading = false;

        // init case tabs
        window.setTimeout(() => {
          savedTabs.forEach(savedTab => {
            let thisCaseObject = this.cases.filter(c => {
              return c.id === savedTab.caseId
            })[0];
            if (thisCaseObject) {
              let newTab = this.initNewTab(thisCaseObject);
              this.tabs.push(newTab);

              const diffActiveTabExists = savedTabs.filter((st) => {
                return st.isActive === true && st.caseId !== savedTab.caseId
              }).length > 0;

              if (!diffActiveTabExists && savedTab.isActive) {
                window.setTimeout(() => {
                  this.setActiveTab(newTab);
                }, 0);
              }
            } else { // else scan to see if inbox was active, and if so, show it
              const inboxIsActive = savedTabs.findIndex(t => t.caseId === INBOX_CASE_ID && t.isActive) > -1;
              if (inboxIsActive) {
                const inboxTab = this.tabs.find(t => {
                  return t.case.id === INBOX_CASE_ID;
                });
                if (inboxTab) {
                  window.setTimeout(() => {
                    this.setActiveTab(inboxTab);
                  }, 0);
                }
              }
            }
          });
        }, 0);


      },
      saveTabs() {
        let savedTabs = this.tabs.map((t, index) => {
          return {
            caseId: t.case.id,
            isActive: index === this.activeTabIndex,
            filters: t.filters
          }
        });

        localStorage.savedTabs = JSON.stringify(savedTabs);
      },
      setAgentInboxTotalCount(value) {
        this.agentInboxTotalCount = value;

        // this seems to be required to force the tab to re-render its title slot and show the correct value
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
    created() {
      let rowsPerPage = parseInt(localStorage.rowsPerPage);
      this.rowsPerPage = rowsPerPage && this.rowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : this.rowsPerPage;


      this.searchTypes()
      .then(() => {
        return this.searchCases();
      })
      .then(() => {
        if(this.cases.length > 0) {
          if (localStorage.savedTabs && JSON.parse(localStorage.savedTabs).length > 0) {
            this.loadSavedTabs();
          } else {
            this.initializeTabs();
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }
      });

      this.getAllTags();
      if (this.userPermissions.canManageUsersAndProducts()) {
        this.searchUsers();
        this.getAllProducts();
      }

      window.addEventListener('beforeunload', this.saveTabs);

      bus.$on('new-census-uploaded', () => {
        this.searchCaseEnrollments();
      });

      bus.$on('case-saving-started', () => {
        this.showSavingModal = true;
      });

      bus.$on('case-saving-ended', () => {
        this.showSavingModal = false;
      });


    },
    computed: {
      userCanCreateEnrollment() {
        return this.userPermissions.canEnroll();
      },
      userCanCreateCase() {
        return this.userPermissions.canCreateCase();
      },
      userCanChangeCase() {
        return this.userPermissions.canChangeCase();
      },
      userCanManageCensus() {
        return this.userPermissions.canManageCensus();
      },
      activeTab() {
        if (this.activeTabIndex < this.tabs.length) {
          return this.tabs[this.activeTabIndex];
        } else {
          return this.tabs[0];
        }
      },
      shouldShowAgentInboxBadge() {
        return this.agentInboxTotalCount > 0;
      }
    },
    watch: {
      rowsPerPage: function() {
        if (!this.isLoading) {
          localStorage.rowsPerPage = this.rowsPerPage;
          this.searchCaseEnrollments();
        }
      },
      currentPage: function() {
        if (!this.isLoading) {
          this.searchCaseEnrollments();
        }
      },
      activeTabIndex: function(newIndex, oldIndex) {
        if (!this.isLoading) {
          this.searchCaseEnrollments();
          this.refreshAgentInbox();
        }
      },
      caseFilterValue: function(newId, oldId) {
      }
    },
    beforeRouteLeave(to, from, next) {
      this.saveTabs();

      next();
    }
}
