<template>

  <div class="card-text">
    <h3>{{ stepTitle }}</h3>
    <enrollment-field-set
      :applicants="applicants"
      :selectedCoverages="selectedCoverages"
      :fields="fields"
      :products="products"
      :applicantContext="applicantContext"
      :coverageContext="coverageContext"
      :globalBeneficiaries="globalBeneficiaries"
      :premiumMode="premiumMode"
      :paymentInformation="paymentInformation"
      :siteConfig="siteConfig"
      @emp-remote-signature-toggled="value => $emit('emp-remote-signature-toggled', value)"

    ></enrollment-field-set>
    <b-row v-if="stepValidationError !== ''">
      <b-col>
        <div class="float-right text-danger">{{ stepValidationError }}</div>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  eofiInput,
  enrollTextInput, enrollEmailInput, enrollDateInput,
  checkboxInput, selectInput, integerInput, radioInput, radioYesNo
} from "./FormInput"
import {Coverage, CoverageList, Applicant, ApplicantList, ProductList, PremiumMode} from "./models";
import {siteConfig} from "../app";


let StepComponent = {
  props: {
    premiumMode: {
      type: PremiumMode,
      required: true
    },
    stepTitle: {
      type: String,
      required: true
    },
    breadcrumb: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    applicants: {
      type: ApplicantList,
      required: true,
    },
    products: {
      type: ProductList,
      required: true,
    },
    selectedCoverages: {
      type: CoverageList,
      required: true
    },
    applicantContext: {
      type: Applicant,
      required: false
    },
    coverageContext: {
      type: Coverage,
      required: false
    },
    coverageOptions: {
      type: Array,
      required: false
    },
    stepValidationError: {
      type: String,
      required: false
    },
    globalBeneficiaries: {
      type: Array,
      required: false
    },
    paymentInformation: {
      type: Object,
      required: false
    },
    siteConfig: {
      type: Object,
      required: true
    },
  },
  data: function () {

    return {}
  },
  methods: {}
};

export default StepComponent;
</script>
