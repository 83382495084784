let modalListenerMixin = {
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId === "new-enrollment-modal-1") {
        this.showEnrollModal = false;
      }
    });
  },
};

export default modalListenerMixin;
