import template from './census-details.html'
import Api from '../api.js'
import moment from "../vendor/moment-timezone-with-data-1970-2030.js";
import formats from '../models/formats'
import colorDefaults from '../models/color-defaults'
import newEnrollmentModal from '../new-enrollment-modal'
import enrollmentStatuses from '../models/enrollment-statuses'
import modalListenerMixin from '../mixins/modalListenerMixin.js';

export default {
    template: template,
    name: "CensusDetailPage",
    components: {
      newEnrollmentModal
    },
    mixins: [modalListenerMixin],
    props: {
      userPermissions: { type: Object },
    },
    data () {
      return {
        caseData: null,
        caseId: null,
        user: {},
        history: [],
        enrollmentDetails: {},
        primary: {},
        spouse: {},
        children: [],
        sortBy: 'enroll_date_created',
        sortDesc: false,
        showAreYouSureModal: false,
        showEnrollModal: false,
        isLoading: true,
        errors: [],
        colorDefaults: colorDefaults,
        newEnrollment: {},
      }
    },
    methods: {
      async getCensusDetails(applicantId) {
        try {
          let response = await Api.getApplicantDetails(applicantId);
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
            if (JSON.stringify(this.errors).includes('permission')) {
              this.$router.push('/403');
            }
          } else {
            this.primary = response;
            this.caseId = response.census_case_id;
            this.caseData = await Api.getCase(this.caseId);

            this.primary.applicant_name = `${response.first_name} ${response.last_name}`;
            if (response.spouse_first) {
              this.spouse = {
                applicant_name: `${response.spouse_first} ${response.spouse_last}`,
                date_of_birth: response.spouse_date_of_birth,
              }
            } else {
              this.spouse = {};
            }
            this.children = [];

          }
        } catch (e) {
          this.errors = [{message: "There was a problem fetching the census data"}];
        }

      },
      formatDate(dateString) {
        return formats.dateToUtc(dateString);
      },
      formatDateTime(dateString) {
        return formats.utcToDateTime(dateString);
      },
      enrollmentClick(record) {
        this.$router.push({name: 'enrollmentDetails', params: {id: record.enrollment_id}});
      },
      async deleteCensus() {
        try {
          const response = await Api.deleteApplicant(this.primary.id);

          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
            this.showAreYouSureModal = false;
          } else {
            this.$router.push({name: 'enrollments'});
          }
        } catch (e) {
          console.error(e);
          this.errors = [{message: "There was a problem deleting the census record."}];
        }
      },
      getAge(dob) {
        return moment(this.enrollmentDetails.enroll_date_created).diff(moment(dob), 'years');
      },
      goToEnrollmentsAndOpenTab(caseId) {
        this.$router.push({name: 'enrollmentsCase', params:{case_id: caseId}});
      },
      openNewEnroll() {
        let selectedCase = this.caseData;
        let enrollmentId = null;
        let applicantName = this.primary.first_name + ' ' + this.primary.last_name;
        let enrollmentCity = this.caseData.situs_city;
        let enrollmentState = this.caseData.situs_state;

        if (localStorage.enrollmentState && localStorage.enrollmentCity) {
          enrollmentCity = localStorage.enrollmentCity;
          enrollmentState = localStorage.enrollmentState;
        }

        this.newEnrollment = {
          caseId: selectedCase.id,
          enrollmentCity: enrollmentCity,
          enrollmentState: enrollmentState,
          enrollmentId: enrollmentId,
          applicantId: this.primary.id,
          name: applicantName,
          ssn: this.primary.ssn,
        };

        this.showEnrollModal = true;
      },
    },
    computed: {

      userCanDeleteApplicant() {
        return this.userPermissions.canManageCensus();
      }
    },
    filters: {
      enrollmentStatus(val) {
        return formats.enrollmentStatus(val);
      }
    },
    created() {
      this.getCensusDetails(this.$route.params.id)
      .then(() => {
        this.isLoading = false;
      });
    }
}
