<template>
  <div class="overflow-auto">
    <h2>Deliveries</h2>
    <b-alert
      :variant="alertVariant"
      dismissible
      :show="showAlert"
      v-text="alertMsg"></b-alert>

    <div v-show="!isLoading">
      <b-pagination
        v-model="currentPage"
        :total-rows="deliveries.length"
        :per-page="perPage"
        aria-controls="deliveries-table"
      ></b-pagination>
      <b-table :items="deliveries" :fields="fields" :per-page="perPage" :current-page="currentPage"
               sort-by="id" :sort-desc="true" striped hover responsive id="deliveries-table">
        <!--
          this is the actions cell slotted template
          - "actions" is the matching field key in `this.fields`
          - "row" is a variable that holds the information about the row data.
            We can access the correspoding row values at `rowdata.item`
        -->
        <template v-slot:cell(actions)="row">
          <b-button size="sm" variant="outline-primary" class="ml-1" @click="showDetails(row.item.id)">
            <font-awesome-icon icon="search"></font-awesome-icon> View
          </b-button>
          <b-button size="sm" variant="outline-primary" class="ml-1" @click="downloadDeliveryPackage(row.item.id)" >
            <font-awesome-icon icon="download"></font-awesome-icon> Download
          </b-button>
          <b-button size="sm" variant="outline-primary" v-if="row.item.should_deliver" class="ml-1" @click="requestDelivery(row.item.id)">
            <font-awesome-icon icon="upload"></font-awesome-icon> Deliver
          </b-button>
          <b-button size="sm" variant="outline-primary" v-if="row.item.can_redeliver" @click="handleRequestRedelivery(row.item.id)" class="ml-1">
            <font-awesome-icon icon="upload"></font-awesome-icon> Re-Deliver
          </b-button>

        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="deliveries.length"
        :per-page="perPage"
        aria-controls="deliveries-table"
      ></b-pagination>

    </div>
    <loading-spinner v-show="isLoading" class="p-3"></loading-spinner>
  </div>
</template>

<script>
import formats from "../models/formats"
import bus from "../bus";
import {router, terminology} from "../app";
import Api from "../api";

export default {

  name: "deliveries",

  created() {
    this.getDeliveries();
  },

  computed: {
    showAlert() {
      return this.alertMsg && this.alertMsg.length > 0;
    }
  },

  methods: {

    getDeliveries() {
      this.isLoading = true;
      Api.getDeliveries()
        .then((resp) => {
          this.deliveries = resp;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showDetails(enrollmentId) {
      router.push({name: 'enrollmentDetails', params: {id: enrollmentId}});
    },

    handleRequestRedelivery(enrollmentId) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to request re-delivery of ID ${enrollmentId}?`)
        .then(value => {
          console.log(`confirmed: ${value}`);
          if (!!value) {
            this.requestDelivery(enrollmentId, true);
          }
        })
    },

    /**
     * Request a redelivery of the given enrollment.
     * @param {Number} enrollmentId
     * @param {Boolean} redeliver
     */
    requestDelivery(enrollmentId, redeliver = false) {
      this.isLoading = true;
      this.alertMsg = '';
      this.alertVariant = 'success';
      Api.requestDelivery(enrollmentId)
        .then((resp) => {
          console.log('got response', resp);
          if (resp && resp.delivery_requested === true) {
            this.alertMsg = `Delivery requested for ID ${enrollmentId}`
          } else {
            console.error('Delivery request failed', resp);
            this.alertMsg = `Delivery request failed for ID ${enrollmentId}`
            // add errors from resp if present
            if (resp && resp.errors) {
              this.alertMsg += ': ' + resp.errors.join(', ');
            }
            this.alertVariant = 'warning';
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.getDeliveries();
        });
    },
    downloadDeliveryPackage(enrollmentID) {
      window.open(process.env.VUE_APP_API_BASE_PATH + '/api/enrollments/' + enrollmentID, '_blank');

      // @TODO maybe rewrite this to use the blob below. the click event didn't seem to work consistently - EF 2022-10-03
      // this.isLoading = true;
      // Api.downloadDeliveryPackage(enrollmentID)
      //   .then((blob) => {
      //     // if this is not a blob, it's an error
      //     if (blob instanceof Blob) {
      //       // create a new blob url
      //       const url = URL.createObjectURL(blob);
      //       // create a new link element
      //       const link = document.createElement('a');
      //       // set the link's href to point to the blob url
      //       link.href = url;
      //
      //       let fileName = `delivery-package-ID${enrollmentID}`;
      //       fileName += `-${moment().format('YYYY-MM-DD-HH-mm-ss')}`;  // append timestamp
      //       fileName += '.json'; // append extension
      //
      //
      //       // set the link's download attribute to our filename
      //       link.setAttribute('download', fileName);
      //       // append the link to the body
      //       document.body.appendChild(link);
      //       // programmatically click the link to trigger the download
      //       link.click();
      //       // remove the link from the body
      //       document.body.removeChild(link);
      //     } else {
      //       console.error('downloadDeliveryPackage: not a blob', blob);
      //       this.alertVariant = 'warning';
      //       this.alertMsg = `Delivery download failed for ID ${enrollmentID}`
      //
      //       // add errors from resp if present
      //       if (blob && blob.errors) {
      //         this.alertMsg += ': ' + resp.errors.join(', ');
      //       }
      //     }
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   });
    }
  },

  data: function () {
    return {
      isLoading: false,
      alertMsg: null,

      currentPage: 1,
      perPage: 100,

      deliveries: [],

      fields: [{
        key: 'id',
        sortable: true,
      }, {
        key: 'status',
        sortable: true,
      }, {
        key: 'delivery_required',
        label: 'Delivery Required?',
        formatter: (value, key, item) => {
          return formats.boolToYesNo(value)
        },
        sortable: true,
      }, {
        key: 'delivered_at',
        label: 'Delivered',
        formatter: (value, key, item) => {
          return formats.utcToDateTime(value, "n/a")
        },
        sortable: true,
      }, {
        key: 'actions',
        label: 'Actions',
        sortable: false,
        class: 'text-right',
      }],

      alertVariant: 'success'  // or 'warning'
    }
  }
}
</script>

<style scoped>
</style>
