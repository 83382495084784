<template>
  <div v-if="staticContent" v-html="staticContent">
  </div>
</template>

<script>
export default {
  name: "StaticPage",
  props: {
    endpoint: {type: String}
  },
  data: function() {
    return {
      staticContent: null
    }
  },
  async created() {
    const response = await fetch(process.env.VUE_APP_API_BASE_PATH + this.endpoint, {
      credentials: "include",
    })

    if (response.status === 200) {
      const json = await response.json();
      if (json.redirect !== undefined) {
        if(json.redirect == "/pages/complete"){
          window.location = '/pages/complete';
          return;
        }
        await this.$router.push(json.redirect);
      } else if (json.html !== undefined) {
        this.staticContent = json.html;
      }
    }

  }
}
</script>

<style scoped>

</style>
