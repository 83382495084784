<template>
  <b-container class="pb-5">

    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <b-link
            @click="$router.push({name:'enrollments'})">
            {{ $root.terminology.formatEnrollment({isTitle: true, isPlural: true}) }}
          </b-link>
        </li>
        <li class="breadcrumb-item active">
          {{ 'enrollment' | terminology({isTitle: true}) }} Detail
        </li>
      </ol>


    </nav>

    <loading-spinner :use-whole-page="true" v-show="isLoading && errors.length === 0"></loading-spinner>

    <div v-if="errors.length > 0" class="col-12">
      <b>Errors<span v-if="errors.length>1">s</span>:</b>
      <ul>
        <li class="text-danger" v-for="error in errors">{{ error.message }}</li>
      </ul>
    </div>

    <b-overlay :show="isLoadingDetails">
      <b-card
        v-show="!isLoading"
        :bg-variant="colorDefaults.card.bgColor"
        :header-text-variant="colorDefaults.card.headerTextColor"
        :header-bg-variant="colorDefaults.card.headerBgColor"
        class="mb-4">
        <h5 slot="header" class="mb-0">{{ 'enrollment' | terminology({isTitle: true}) }} Detail</h5>

        <div class="row">

          <div class="col-12 col-xl-6 column">
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                {{ 'case' | terminology({isTitle: true}) }}:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                <b-link :to="{name: 'enrollments', params: {case_id: enrollmentDetails.case_id}}">
                  {{ enrollmentDetails.case_group_name }}
                </b-link>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                Insured:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                {{ enrollmentDetails.insured_name }}
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                {{ enrollmentTimestampText }}:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                {{ enrollmentTimestamp }}
              </div>
            </div>
            <div class="row py-1">
              <div class="col-12 col-md-4 col-lg-3">
                Agent:
              </div>
              <div class="col-12 col-md-8 col-lg-9">
                <div class="form-group">
                  <b-form-row>
                    <b-col cols="6">{{ enrollmentDetails.agent_name }}</b-col>
                    <b-col class="text-right" v-if="showEnrollingAgentSplit">{{ selectedAgent1Split }}%</b-col>
                    <b-col cols="2"></b-col>
                  </b-form-row>
                </div>
              </div>
            </div>
            <div class="row py-1"
                 v-if="enrollmentDetails.status !== 'waived' && (enrollmentDetails.case_agent_splits_enabled || hasExistingSplitAgents)">
              <div class="col-12 col-md-4 col-lg-3">
                Split Agents:
              </div>
              <div class="col-12 col-md-8 col-lg-9">
                <!-- <div v-if="canAddOrEditSplitAgents">
                  <b-button size="sm" variant="link" class="p-0" :pressed.sync="showEditSplitAgents"
                            @click="checkSplitAgentEntry" v-if="!showEditSplitAgents">
                    <span v-if="!hasSplitAgents">Add split agents…</span>
                    <span v-else>Edit split agents…</span>
                  </b-button>
                </div> -->

                <div v-if="!showEditSplitAgents && !hasSplitAgents">
                  None
                </div>
                <div v-if="!showEditSplitAgents && hasSplitAgents">
                  <div class="form-group">
                    <b-form-row v-for="(splitAgent, index) in splitAgents" class="pb-1">
                      <b-col cols="7">{{ getCaseUserName(splitAgent.agent) }}</b-col>
                      <b-col class="text-right">{{ splitAgent.agentSplit }}%</b-col>
                      <b-col cols="2"></b-col>
                    </b-form-row>
                  </div>
                </div>
                <!-- <div v-if="showEditSplitAgents">
                  <b-form @submit="onSplitAgentsSubmit">
                    <div class="form-group">

                      <b-form-row v-for="(splitAgent, index) in splitAgents" class="pt-1">
                        <b-col cols="6">
                          <multiselect
                            v-model="splitAgent.agent"
                            :options="caseUsers"
                            track-by="id"
                            :hide-selected="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="true"
                            :multiple="false"
                            :customLabel="getCaseUserName"
                            placeholder="Choose agent"
                            open-direction="bottom"
                          >
                          </multiselect>
                        </b-col>
                        <b-col>
                        <span class="text-nowrap">
                          <b-form-input class="text-right" size="md" v-model="splitAgent.agentSplit"
                                        @change="checkSplitValues" type="number" min="0" max="100"
                                        step="1"></b-form-input>
                        </span>
                        </b-col>
                        <b-col cols="2" class="text-right">
                          <b-button block @click="removeSplitAgent(index)" variant="outline-secondary" size="md"><i
                            class="fa fa-trash"></i></b-button>
                        </b-col>
                      </b-form-row>

                      <b-form-row class="pt-1">
                        <b-col cols="6">
                          <b-button v-if="canAddSplitAgents" variant="outline-secondary" size="sm"
                                    @click="addSplitAgent(null, 0)">+
                          </b-button>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <b-button variant="outline-secondary" size="sm" @click="cancelSplitAgentEdit">Cancel
                          </b-button>
                          <b-button type="submit" variant="primary" size="sm">Save</b-button>
                        </b-col>
                      </b-form-row>
                    </div>
                  </b-form>
                </div> -->

              </div>
            </div>
          </div>

          <div class="col-12 col-xl-6 column">
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                Product:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                {{ enrollmentDetails.product_name }}
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                Coverage:
              </div>
              <div class="col-6 col-md-8 col-lg-9" v-show="!isExpired">
                {{ enrollmentDetails.coverage_sum }}
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                Annualized Premium:
              </div>
              <div class="col-6 col-md-8 col-lg-9" v-show="!isExpired">
                {{ enrollmentDetails.annual_premium_sum }}
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                Status:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                {{ enrollmentDetails.status | enrollmentStatus }}
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6 col-md-4 col-lg-3">
                Signed:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                <ul class="pl-3 mb-0">
                  <li class="ml-0" v-if="applicantSignatureDetails">{{ applicantSignatureDetails }}</li>
                  <li class="ml-0" v-if="agentSignatureDetails">{{ agentSignatureDetails }}</li>
                </ul>
              </div>
            </div>
            <div class="row py-1" v-if="!isExpired && !isWaived">
              <div class="col-6 col-md-4 col-lg-3">
                Transmitted:
              </div>
              <div class="col-6 col-md-8 col-lg-9">
                {{ enrollmentDetails.delivered_at }}
              </div>
            </div>
          </div>

        </div>

        <b-row class="py-1" v-if="recordings && recordings.length > 0">
          <b-col cols="12">
            <h5>{{ "enrollment" | terminology({isTitle: true}) }} Voice Recordings</h5>
            <hr>
          </b-col>
          <b-col md="6" v-for="recording in recordings">
            <b-card
              :bg-variant="colorDefaults.recordingPlaybackCard.bgColor"
              :header-text-variant="colorDefaults.recordingPlaybackCard.headerTextColor"
              :header-bg-variant="colorDefaults.recordingPlaybackCard.headerBgColor"
              :footer-bg-variant="colorDefaults.recordingPlaybackCard.footerBgColor"
            >
              <template v-slot:header>
                <h6 v-text="formatDateTime(recording.start_time)"></h6>
              </template>
              <b-card-text>
                <div v-if="recording.localPlaybackUrl">
                  <audio class="recordingPlaybackWidget" controls :src="recording.localPlaybackUrl"
                         v-if="!!recording.localPlaybackUrl">
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                </div>
                <div v-else>
                  <small class="text-muted">
                    <b-spinner small type="grow" variant="primary"></b-spinner>
                    This recording is still processing.</small>
                </div>
              </b-card-text>
              <template v-slot:footer>
                <div v-if="recording.mp3_url">
                  <b-row no-gutters>
                    <b-col cols="6">
                      <small class="text-muted">
                        Call length: <span v-text="formatRecordingDuration(recording.duration)"></span>
                      </small>
                    </b-col>
                    <b-col cols="6" class="text-a">
                      <b-button size="sm" @click="downloadRecording(recording)" variant="link" class="float-right">
                        <font-awesome-icon icon="download"></font-awesome-icon>
                        Download
                      </b-button>
                    </b-col>
                  </b-row>

                </div>

              </template>
            </b-card>
          </b-col>
        </b-row>

        <div class="row px-3 pt-3">
          <button
            type="button"
            class="btn btn-outline-secondary col-sm-0 mr-3 mb-3 mb-md-0"
            @click="showPDFModal = true"
            :disabled ="enrollmentDetails.status === 'waived'"
            v-if="(enrollmentDetails.status !== 'waived' && enrollmentDetails.status !== 'declined') || this.userPermissions.canViewDeclinedWaivedEnrollments()">
            <i class="far fa-file-pdf"></i> View App Form
            <span v-if="(enrollmentDetails.status === 'waived' || enrollmentDetails.status === 'declined')" class="text-muted small">Admins Only</span>
          </button>

          <pdf-modal :enrollment-id="enrollmentDetails.id" :visible="showPDFModal"
                      @hidden="showPDFModal = false">
          </pdf-modal>

          <button
            v-if="userCanDeleteEnrollment"
            type="button"
            class="btn btn-outline-danger col-sm-0 mr-3 mb-3 mb-md-0"
            @click="areYouSure(enrollmentDetails.id)">
            <i class="fa fa-trash"></i> Delete this {{ 'enrollment' | terminology({isTitle: false}) }}
          </button>
          <span v-if="isPaused">
          <button
            type="button"
            class="btn btn-outline-success col-sm-0 mr-3 mb-3 mb-md-0"
            @click="openNewEnroll()">
            <font-awesome-icon
              icon="play-circle"></font-awesome-icon> Resume {{ 'enrollment' | terminology({isTitle: true}) }}
          </button>
        </span>
          <span v-if="canCreateNewEnrollment">
          <button
            type="button"
            class="btn btn-outline-success col-sm-0 mr-3 mb-3 mb-md-0"
            @click="openNewEnroll()">
            <font-awesome-icon icon="plus"></font-awesome-icon> New {{ 'enrollment' | terminology({isTitle: true}) }}
          </button>
        </span>
          <button
            v-if="showSendRemoteSignatureEmail"
            type="button"
            class="btn btn-outline-secondary col-sm-0"
            @click="showSendRemoteSignatureModal = true">
            <i class="fa fa-envelope"></i> Resend email for signature
          </button>
        </div>

      </b-card>

      <b-card
        v-show="!isLoading"
        :header-text-variant="colorDefaults.card.headerTextColor"
        :header-bg-variant="colorDefaults.card.headerBgColor"
        class="table-card mb-4">
        <h5 slot="header" class="mb-0">{{ 'enrollment' | terminology({isTitle: true}) }} History</h5>

        <b-table
          striped
          hover
          :items="history"
          :fields="fields"
          class="mb-0 pointer"
          @row-clicked="enrollmentClick"
          @sort-changed="sortChanged"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          no-local-sorting>

          <template slot="view" slot-scope="row">
            <b-button size="sm"
                      v-if="row.item.enrollment_status != 'waived'"
                      @click="viewEnrollmentPDF(row.item.enrollment_id)"
                      class="mr-2">
              <i class="far fa-file-pdf"></i>
            </b-button>
          </template>

        </b-table>
      </b-card>

      <b-card
        v-show="!isLoading"
        :bg-variant="colorDefaults.card.bgColor"
        :header-text-variant="colorDefaults.card.headerTextColor"
        :header-bg-variant="colorDefaults.card.headerBgColor">
        <h5 slot="header" class="mb-0">Census Information</h5>

        <div class="row">

          <div class="col-6">
            {{ primary.applicant_name }}
            <span v-if="primary.address1"><br>{{ primary.address1 }} {{ primary.address2 }}</span>
            <span v-if="primary.city"><br>{{ primary.city }},</span> {{ primary.state }} {{ primary.zip_code }}
          </div>

          <div class="col-6">
            dob {{ formatDate(primary.date_of_birth) }} (age {{ getAge(primary.date_of_birth) }})
            <span v-if="primary.email"><br>{{ primary.email }}</span>
            <span v-if="primary.phone"><br>{{ primary.phone }}</span>
          </div>

        </div>

        <table class="census  mt-3">
          <tr v-if="spouse.applicant_name">
            <td>spouse:</td>
            <td>{{ spouse.applicant_name }}</td>
            <td>{{ formatDate(spouse.date_of_birth) }} (age {{ getAge(spouse.date_of_birth) }})</td>
          </tr>
          <tr v-for="(child, index) in children">
            <td>{{ index === 0 ? 'children:' : '' }}</td>
            <td>{{ child.applicant_name }}</td>
            <td>{{ formatDate(child.date_of_birth) }} (age {{ getAge(child.date_of_birth) }})</td>
          </tr>
        </table>

      </b-card>

      <b-modal
        :title="`Are you sure you want to delete this ${$root.terminology.formatEnrollment({})}?`"
        v-model="showAreYouSureModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">

        <b-container>
          Deleting this {{ "enrollment" | terminology({}) }} record is permanent and cannot be undone. Are you sure?
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn size="md" class="col-0 mr-3" variant="outline-secondary" @click="showAreYouSureModal=false">
            <i class="fa fa-times"></i> No, return to details
          </b-btn>
          <b-btn size="md" class="col-0 mr-3" variant="danger" @click="deleteEnrollment">
            Yes, delete this {{ "enrollment" | terminology({}) }}
          </b-btn>
        </div>
      </b-modal>

    </b-overlay>


    <new-enrollment-modal
      v-if="showEnrollModal"
      :newEnrollment="newEnrollment"
      :casesFilterOptions="casesFilterOptions"
      v-on:close-new-enrollment-modal="showEnrollModal=false">
    </new-enrollment-modal>

    <b-modal v-model="showSendRemoteSignatureModal"
             title="Resend Email Request"
             cancel-title="Close"
             cancel-variant="outline-secondary"
             ok-title="Submit"
             ok-variant="success"
             :busy="sendRemoteSignatureModalBusy"
             ref="sendRemoteSignatureModal"
             @ok="sendRemoteSignatureEmail($event, enrollmentDetails.session_id)">
      <div>
        <b-row>
          <b-col>Resend email of completed application to the applicant for review and electronic signature.</b-col>
        </b-row>
        <b-row>
          <b-col>
            <div role="group">
              <label for="remote-signature-email"><strong>Confirm email address:</strong></label>
              <b-form-input
                id="remote-signature-email"
                v-model="remoteSignatureEmail"
                type="email"
                placeholder=""></b-form-input>
            </div>
          </b-col>
        </b-row>
        <div v-if="sendRemoteSignatureModalErrors.length > 0" class="col-12 mt-4">
          <b>Errors<span v-if="sendRemoteSignatureModalErrors.length>1">s</span>:</b>
          <ul>
            <li class="text-danger" v-for="error in sendRemoteSignatureModalErrors">{{ error.message }}</li>
          </ul>
        </div>
      </div>
    </b-modal>
  </b-container>


</template>

<script>
import Api from './api.js'
import moment from "./vendor/moment-timezone-with-data-1970-2030.js";
import PdfModal from "./PdfModal";
import formats from './models/formats'
import colorDefaults from './models/color-defaults'
import newEnrollmentModal from './new-enrollment-modal'
import enrollmentStatuses from './models/enrollment-statuses'
import {siteConfig, terminology} from "./app";
import UserPermissions from "./models/user_permissions";
import api from "./api";
import modalListenerMixin from './mixins/modalListenerMixin.js';

const SPLIT_AGENT_KEYS_PREFIXES = ['agent_2', 'agent_3', 'agent_4', 'agent_5',];
const SPLIT_AGENT_MAX = 9;

const SIG_TYPE_AGENT = 'agent';
const SIG_TYPE_APPLICANT = 'applicant';
const SIG_STATUS_SIGNED = 'signed';
const SIG_STATUS_PENDING = 'pending';
const SIG_METHOD_INPERSON = 'inperson';
const SIG_METHOD_REMOTE = 'remote';
const SIG_METHOD_SELFENROLL = 'selfenroll';
const SIG_METHOD_VOICE = 'voice';
const SIG_METHOD_AUDIOENROLL = 'audioenroll';

const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;

export default {
  name: "enrollmentsDetails",
  components: {
    newEnrollmentModal,
    PdfModal,
  },
  mixins: [modalListenerMixin],
  props: {
    userPermissions: {type: UserPermissions},
  },
  data() {
    let fields = [
      'view',
      {
        key: 'enroll_date_created',
        label: 'Date Started',
        formatter: (dateString) => {
          return formats.utcToLocalDateTime(dateString);
        }
      },
      {
        key: 'insured_name',
        label: 'Insured',
      },
      {
        key: 'employee_name',
        label: this.$root.terminology.formatEmployee({isTitle: true}),
      },
      {
        key: 'product_name',
        label: 'Product',
      },
      {
        key: 'coverage_sum',
        label: 'Coverage',
      },
      {
        key: 'monthly_premium_sum',
        label: 'Monthly Premium',
        formatter: (amount) => {
          return formats.premium(amount);
        }
      },
    ];
    // Site-wide config changes to columns;
    if (!siteConfig.shouldShowEmployeeColumns) {
      fields = fields.filter((c) => c.key !== 'employee_name')
    }
    return {
      fields: fields,
      breadCrumbs: [{
        text: 'Enrollments',
        route: {name: 'enrollments'},
      }, {
        text: 'Enrollment Detail',
        route: '#'
      }],
      user: {},
      history: [],
      enrollmentDetails: {},
      primary: {},
      spouse: {},
      children: [],
      sortBy: 'enroll_date_created',
      sortDesc: false,
      showAreYouSureModal: false,
      showEnrollModal: false,
      showSendRemoteSignatureModal: false,
      sendRemoteSignatureModalErrors: [],
      sendRemoteSignatureModalBusy: false,
      isLoading: true,
      isLoadingDetails: false,
      errors: [],
      colorDefaults: colorDefaults,
      newEnrollment: {},
      casesFilterOptions: [],
      remoteSignatureEmail: null,
      caseUsers: [],

      splitAgents: [],
      showEditSplitAgents: false,
      selectedAgent1Split: 0,

      selectedAgent2: null,
      selectedAgent2Split: 0,
      selectedAgent3: null,
      selectedAgent3Split: 0,
      selectedAgent4: null,
      selectedAgent4Split: 0,
      selectedAgent5: null,
      selectedAgent5Split: 0,
      selectedAgent6: null,
      selectedAgent6Split: 0,
      selectedAgent7: null,
      selectedAgent7Split: 0,
      selectedAgent8: null,
      selectedAgent8Split: 0,
      selectedAgent9: null,
      selectedAgent9Split: 0,
      selectedAgent10: null,
      selectedAgent10Split: 0,

      recordings: [],

      viewingEnrollmentId: null,
      showPDFModal: false,
    }
  },
  methods: {
    // viewPdf(enrollmentId) {
    //
    //   this.viewingEnrollmentId = enrollmentId;
    //   // toggle! - EF 2022-09-08
    //   this.showPDFModal = true;
    // },
    getEnrollmentDetails(enrollmentId) {
      return Api.getEnrollmentDetails(enrollmentId)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
            if (JSON.stringify(this.errors).includes('permission')) {
              this.$router.push('/403');
            }
          } else {
            this.enrollmentDetails = response;

            // reformat waived and paused enrollments in History
            this.enrollmentDetails.enrollment_history.forEach(row => {
              if (row.enrollment_status === enrollmentStatuses.STATUS_WAIVED) {
                row.coverage_sum = 'Waived';
                return
              }

              if (row.enrollment_status === enrollmentStatuses.STATUS_EXPIRED) {
                row.coverage_sum = 'Expired';
                row.monthly_premium_sum = '';
                return;
              }

              row.coverage_sum = formats.coverage(row.coverage_sum)
            });

            this.remoteSignatureEmail = this.enrollmentDetails.remote_signature_email;
            this.primary = this.enrollmentDetails.census.primary;
            this.spouse = this.enrollmentDetails.census.spouse;
            this.children = this.enrollmentDetails.census.children;
            this.history = this.enrollmentDetails.enrollment_history;
            this.enrollmentDetails.coverage_sum = formats.coverage(this.enrollmentDetails.coverage_sum);
            this.enrollmentDetails.annual_premium_sum = formats.premium(this.enrollmentDetails.annual_premium_sum);
            let false_msg = (this.isPaused || this.isWaived) ? "" : 'queued';
            this.enrollmentDetails.delivered_at = formats.utcToDateTime(this.enrollmentDetails.delivered_at, false_msg);
            this.enrollmentDetails.uw_submitted_at = formats.localToDateTime(this.enrollmentDetails.uw_submitted_at, false_msg);
          }
        });
    },
    getRecordings(enrollmentId) {
      return Api.getEnrollmentRecordings(enrollmentId)
        .then(response => {
          this.recordings = response;

          // add .localPlaybackUrl = null to all recordings objs. This will store the local Blob URL for playback
          let localRecordingPromises = [];

          this.recordings.forEach(recording => {
            recording.localPlaybackUrl = null;

            let localRecordingPromise = this.retrieveRecordingDataForPlayback(recording);
            localRecordingPromises.push(localRecordingPromise);

            localRecordingPromise.then((localRecording) => {
                recording = localRecording;
                console.debug(`Retrieved local recording data ${recording}`);
              })
              .catch((error) => {
                console.error(`Error retrieving local recording data ${recording} for playback: ${error}`);
              });
          });

          // wait for all local recordings to be retrieved before updating the UI
          Promise.all(localRecordingPromises)
            .then(() => {
              // force deep update of recordings
              this.recordings = [...this.recordings];
            })
        });
    },
    formatDate(dateString) {
      return formats.dateToUtc(dateString);
    },
    formatDateTime(dateString) {
      return formats.utcToDateTime(dateString);
    },
    formatRecordingDuration(numSeconds) {
      return formats.durationFromSeconds(numSeconds)
    },
    sortChanged(header) {

    },
    enrollmentClick(record) {
      if(this.$route.params.id !== record.enrollment_id) {
      this.$router.push({name: 'enrollmentDetails', params: {id: record.enrollment_id}});
       }
    },
    deleteEnrollment() {
      Api.deleteEnrollment(this.enrollmentDetails.id)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.$router.push({name: 'enrollments'});
          }
        });
    },
    areYouSure(enrollmentId) {
      this.showAreYouSureModal = true;
      this.areYouSureEnrollmentId = enrollmentId;
    },
    getAge(dob) {
      return moment(this.enrollmentDetails.enroll_date_created).diff(moment(dob), 'years');
    },
    goToEnrollmentsAndOpenTab(caseId) {
      this.$router.push({name: 'enrollmentsCase', params: {case_id: caseId}});
    },
    searchCases() {
      return Api.searchCases()
        .then(response => {
          this.cases = response;
          this.casesFilterOptions = this.mapCases();
          this.caseFilterValue = this.enrollmentDetails.case_id;
        });
    },
    getCaseUsers(caseId) {
      return Api.getUsersForCase(caseId)
        .then(response => {
          this.caseUsers = response;
        });
    },
    getCaseUsersAccess(caseId) {
      return Api.getAgentsWithAccess(caseId)
        .then(response => {
          this.caseUsersAccess = response;
        });
    },
    getCaseUserName(obj) {
      return `${obj.first_name} ${obj.last_name}`;
    },
    mapCases() {
      let options = this.cases.map(c => {
        return {text: c.group_name, value: c.id};
      });
      options = formats.alphebetize(options, 'text');
      options.unshift({text: 'Select Case', value: 0});
      return options;
    },
    openNewEnroll(startAudioConference = false) {
      // @TODO if resuming, we need to determine if previously an audio conference call and try to resume that

      let selectedCase = this.cases.filter(c => c.id === this.caseFilterValue)[0];
      let enrollmentId = this.enrollmentDetails.id;
      let applicantName = this.enrollmentDetails.insured_name;

      // Get defaults for city and state. Prioritize local settings over case settings.
      let enrollmentCityDefault = selectedCase.situs_city;
      let enrollmentStateDefault = selectedCase.situs_state;
      if (localStorage.enrollmentCity && localStorage.enrollmentState) {
        enrollmentCityDefault = localStorage.enrollmentCity;
        enrollmentStateDefault = localStorage.enrollmentState;
      }

      let isAudioConferenceEnrollment = startAudioConference;
      let primaryApplicantNumber = null;
      let agentNumber = null;

      // If we have done previous conferences, assume we will do one again.
      // @TODO test this! -Ed F 2021-11-12
      if (this.enrollmentDetails.enrollment_session_conferences) {
        isAudioConferenceEnrollment = true;
        const lastConference = this.enrollmentDetails.enrollment_session_conferences[this.enrollmentDetails.enrollment_session_conferences.length - 1];
        if (lastConference) {
          agentNumber = lastConference.agent_number;
          primaryApplicantNumber = lastConference.primary_applicant_number;
        }
      }

      this.newEnrollment = {
        caseId: selectedCase.id,
        enrollmentCity: enrollmentCityDefault,
        enrollmentState: enrollmentStateDefault,
        enrollmentId: enrollmentId,
        name: applicantName,
        isPaused: this.isPaused,
        primaryApplicantNumber: primaryApplicantNumber,
        agentNumber: agentNumber,
        isAudioConferenceEnrollment: isAudioConferenceEnrollment,
      }

      this.showEnrollModal = true;
    },

    sendRemoteSignatureEmail($event, session_id) {
      $event.preventDefault();  // keep modal from closing immediately

      this.sendRemoteSignatureModalBusy = true; // disable buttons
      this.sendRemoteSignatureModalErrors = [];

      // TOOD handle the validation and such
      Api.sendRemoteSignatureEmail(session_id, this.remoteSignatureEmail)
        .then(response => {
          this.sendRemoteSignatureModalBusy = false; // re-enable buttons
          if (response.errors && response.errors.length > 0) {
            // don't close the modal, show errors
            this.sendRemoteSignatureModalErrors = response.errors;
          } else {
            // hide the modal
            this.$refs.sendRemoteSignatureModal.hide()
          }
        });
    },

    addSplitAgent(agent, agentSplit) {
      // if (this.splitAgents.length >= SPLIT_AGENT_MAX) {
      //   this.errors = [{
      //     "message": `A maximum of ${SPLIT_AGENT_MAX} split agents can be added.`
      //   }];
      //   return;
      // }

      if (!agent) {
        agent = null;
      }
      if (!agentSplit) {
        agentSplit = 0;
      }

      this.splitAgents.push({agent: agent, agentSplit: agentSplit});
    },

    removeSplitAgent(index) {
      this.splitAgents.splice(index, 1);

      // recalculate on remove
      this.loadFromSplitAgentRows();
      this.checkSplitValues();
    },

    checkSplitAgentEntry() {
      if (this.showEditSplitAgents && !this.hasSplitAgents) {
        this.addSplitAgent(null, 0);
      }
    },

    cancelSplitAgentEdit() {
      this.showEditSplitAgents = false;
      this.loadAgentSplitsFromEnrollmentDetails();
    },

    populateSplitAgentRows() {
      this.splitAgents = [];

      if (this.selectedAgent2) {
        this.addSplitAgent(this.selectedAgent2, this.selectedAgent2Split);
      }
      if (this.selectedAgent3) {
        this.addSplitAgent(this.selectedAgent3, this.selectedAgent3Split);
      }
      if (this.selectedAgent4) {
        this.addSplitAgent(this.selectedAgent4, this.selectedAgent4Split);
      }
      if (this.selectedAgent5) {
        this.addSplitAgent(this.selectedAgent5, this.selectedAgent5Split);
      }
      if (this.selectedAgent6) {
        this.addSplitAgent(this.selectedAgent6, this.selectedAgent6Split);
      }
      if (this.selectedAgent7) {
        this.addSplitAgent(this.selectedAgent7, this.selectedAgent7Split);
      }
      if (this.selectedAgent8) {
        this.addSplitAgent(this.selectedAgent8, this.selectedAgent8Split);
      }
      if (this.selectedAgent9) {
        this.addSplitAgent(this.selectedAgent9, this.selectedAgent9Split);
      }
      if (this.selectedAgent10) {
        this.addSplitAgent(this.selectedAgent10, this.selectedAgent10Split);
      }

    },

    loadFromSplitAgentRows() {
      // reset state entirely
      this.selectedAgent2 = this.selectedAgent3 = this.selectedAgent4 = this.selectedAgent5 = null;
      this.selectedAgent2Split = this.selectedAgent3Split = this.selectedAgent4Split = this.selectedAgent5Split = 0;
      this.selectedAgent1Split = 100;

      if (this.splitAgents.length < 1) {
        return;
      }

      for (let i = 0; i < this.splitAgents.length; i++) {
        if (i === 0) {
          this.selectedAgent2 = this.splitAgents[i].agent ? this.splitAgents[i].agent : null;
          this.selectedAgent2Split = this.splitAgents[i].agentSplit;
        }
        if (i === 1) {
          this.selectedAgent3 = this.splitAgents[i].agent ? this.splitAgents[i].agent : null;
          this.selectedAgent3Split = this.splitAgents[i].agentSplit;
        }
        if (i === 2) {
          this.selectedAgent4 = this.splitAgents[i].agent ? this.splitAgents[i].agent : null;
          this.selectedAgent4Split = this.splitAgents[i].agentSplit;
        }
        if (i === 3) {
          this.selectedAgent5 = this.splitAgents[i].agent ? this.splitAgents[i].agent : null;
          this.selectedAgent5Split = this.splitAgents[i].agentSplit;
        }
      }
    },

    makeAgentSplitsArray() {
      let agentSplits = [];

      // note: we cast the splits to strings so they are handled as decimals on the backend

      // always add the primary agent
      agentSplits.push({'id': this.enrollmentDetails.agent_id, 'split': this.selectedAgent1Split + ''});

      if (this.selectedAgent2 && this.selectedAgent2Split) {
        agentSplits.push({'id': this.selectedAgent2.id, 'split': this.selectedAgent2Split + ''})
      }
      if (this.selectedAgent3 && this.selectedAgent3Split) {
        agentSplits.push({'id': this.selectedAgent3.id, 'split': this.selectedAgent3Split + ''})
      }
      if (this.selectedAgent4 && this.selectedAgent4Split) {
        agentSplits.push({'id': this.selectedAgent4.id, 'split': this.selectedAgent4Split + ''})
      }
      if (this.selectedAgent5 && this.selectedAgent5Split) {
        agentSplits.push({'id': this.selectedAgent5.id, 'split': this.selectedAgent5Split + ''})
      }
      return agentSplits;
    },

    checkSplitValues() {
      this.loadFromSplitAgentRows();
      const maxPerc = 100 * 100;

      let agent1split = parseInt(this.selectedAgent1Split * 100, 10);
      let agent2split = parseInt(this.selectedAgent2Split * 100, 10);
      let agent3split = parseInt(this.selectedAgent3Split * 100, 10);
      let agent4split = parseInt(this.selectedAgent4Split * 100, 10);
      let agent5split = parseInt(this.selectedAgent5Split * 100, 10);

      let splitAgentSum = (agent2split + agent3split + agent4split + agent5split);
      if (splitAgentSum > maxPerc) {
        this.errors = [{
          "message": "Agent split values cannot exceed 100%",
        }];
        return false;
      }

      agent1split = maxPerc - splitAgentSum;

      // set values in form to decimals
      this.selectedAgent1Split = agent1split / 100;
      this.selectedAgent2Split = agent2split / 100;
      this.selectedAgent3Split = agent3split / 100;
      this.selectedAgent4Split = agent4split / 100;
      this.selectedAgent5Split = agent5split / 100;
      return true;
    },

    checkSplitAgents() {
      let errors = [];

      if (this.selectedAgent2Split && this.selectedAgent2Split > 0) {
        if (!this.selectedAgent2) {
          errors.push({'message': 'Split Agent 2 is not set.'});
        }
      }
      if (this.selectedAgent3Split && this.selectedAgent3Split > 0) {
        if (!this.selectedAgent3) {
          errors.push({'message': 'Split Agent 3 is not set.'});
        }
      }
      if (this.selectedAgent4Split && this.selectedAgent4Split > 0) {
        if (!this.selectedAgent4) {
          errors.push({'message': 'Split Agent 4 is not set.'});
        }
      }
      if (this.selectedAgent5Split && this.selectedAgent5Split > 0) {
        if (!this.selectedAgent5) {
          errors.push({'message': 'Split Agent 5 is not set.'});
        }
      }

      // check for any repeat agents
      const splitAgents = [this.selectedAgent2, this.selectedAgent3, this.selectedAgent4, this.selectedAgent5];
      let splitAgentsFound = [];
      for (let i = 0; i < splitAgents.length; i++) {
        if (splitAgents[i]) {
          if (splitAgents[i].id === this.enrollmentDetails.agent_id) {
            errors.push({'message': 'The enrolling agent cannot be a split agent.'});
          } else if (splitAgentsFound.includes(splitAgents[i].id)) {
            errors.push({'message': `${this.getCaseUserName(splitAgents[i])} is listed more than once as a split agent.`});
          } else {
            splitAgentsFound.push(splitAgents[i].id);
          }
        }
      }

      if (errors.length > 0) {
        this.errors = errors;
        return false;
      }

      return true;
    },

    onSplitAgentsSubmit($event) {
      this.errors = [];

      this.loadFromSplitAgentRows();

      if (!this.checkSplitValues()) {
        return;
      }

      if (!this.checkSplitAgents()) {
        return;
      }

      let agentSplits = this.makeAgentSplitsArray();

      this.isLoading = true;

      Api.updateEnrollmentAgentSplits(this.enrollmentDetails.id, agentSplits)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.isLoading = false;
            this.errors = response.errors;
          } else {
            this.getEnrollmentDetails(this.enrollmentDetails.id)
              .then(() => {
                this.loadAgentSplitsFromEnrollmentDetails();
                this.isLoading = false;
                this.showEditSplitAgents = false;
              }).catch(() => {
              this.isLoading = false;
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getCaseUserById(id) {
      console.log("The user are", this.caseUsers)
      let user;
      if(this.caseUsers.errors){
        user = this.caseUsersAccess.find((caseUser) => {
          return caseUser.id === id
        });
      }
      else{
        user = this.caseUsers.find((caseUser) => {
          return caseUser.id === id
        });
      }

      if (!user) {
        return null
      }
      return user;
    },

    loadAgentSplitsFromEnrollmentDetails() {
      this.selectedAgent1Split = this.enrollmentDetails.agent_split;

      if (this.enrollmentDetails.agent_2_id) {
        this.selectedAgent2 = this.getCaseUserById(this.enrollmentDetails.agent_2_id);
        this.selectedAgent2Split = this.enrollmentDetails.agent_2_split;
      } else {
        this.selectedAgent2 = null;
        this.selectedAgent2Split = 0;
      }

      if (this.enrollmentDetails.agent_3_id) {
        this.selectedAgent3 = this.getCaseUserById(this.enrollmentDetails.agent_3_id);
        this.selectedAgent3Split = this.enrollmentDetails.agent_3_split;
      } else {
        this.selectedAgent3 = null;
        this.selectedAgent3Split = 0;
      }

      if (this.enrollmentDetails.agent_4_id) {
        this.selectedAgent4 = this.getCaseUserById(this.enrollmentDetails.agent_4_id);
        this.selectedAgent4Split = this.enrollmentDetails.agent_4_split;
      } else {
        this.selectedAgent4 = null;
        this.selectedAgent4Split = 0;
      }

      if (this.enrollmentDetails.agent_5_id) {
        this.selectedAgent5 = this.getCaseUserById(this.enrollmentDetails.agent_5_id);
        this.selectedAgent5Split = this.enrollmentDetails.agent_5_split;
      } else {
        this.selectedAgent5 = null;
        this.selectedAgent5Split = 0;
      }

      if (this.enrollmentDetails.agent_6_id) {
        this.selectedAgent6 = this.getCaseUserById(this.enrollmentDetails.agent_6_id);
        this.selectedAgent6Split = this.enrollmentDetails.agent_6_split;
      } else {
        this.selectedAgent6 = null;
        this.selectedAgent6Split = 0;
      }

      if (this.enrollmentDetails.agent_7_id) {
        this.selectedAgent7 = this.getCaseUserById(this.enrollmentDetails.agent_7_id);
        this.selectedAgent7Split = this.enrollmentDetails.agent_7_split;
      } else {
        this.selectedAgent7 = null;
        this.selectedAgent7Split = 0;
      }

      if (this.enrollmentDetails.agent_8_id) {
        this.selectedAgent8 = this.getCaseUserById(this.enrollmentDetails.agent_8_id);
        this.selectedAgent8Split = this.enrollmentDetails.agent_8_split;
      } else {
        this.selectedAgent8 = null;
        this.selectedAgent8Split = 0;
      }

      if (this.enrollmentDetails.agent_9_id) {
        this.selectedAgent9 = this.getCaseUserById(this.enrollmentDetails.agent_9_id);
        this.selectedAgent9Split = this.enrollmentDetails.agent_9_split;
      } else {
        this.selectedAgent9 = null;
        this.selectedAgent9Split = 0;
      }

      if (this.enrollmentDetails.agent_10_id) {
        this.selectedAgent10 = this.getCaseUserById(this.enrollmentDetails.agent_10_id);
        this.selectedAgent10Split = this.enrollmentDetails.agent_10_split;
      } else {
        this.selectedAgent10 = null;
        this.selectedAgent10Split = 0;
      }

      this.populateSplitAgentRows();
    },

    /**
     *
     * @param sig_type
     * @returns {null|*}
     * @private
     */
    _getSignature(sig_type) {
      if (!this.enrollmentDetails.enrollment_signatures) {
        return null;
      }
      if (sig_type === SIG_TYPE_AGENT) {
        return this.enrollmentDetails.enrollment_signatures.find(sig => {
          if (sig.agent_id != null && sig.agent_id > 0) {
            return sig
          }
        });
      } else if (sig_type === SIG_TYPE_APPLICANT) {
        return this.enrollmentDetails.enrollment_signatures.find(sig => {
          if (sig.applicant_id != null && sig.applicant_id > 0) {
            return sig
          }
        });
      }
      throw new Error("Invalid signature type.");
    },

    // Signed: in-person 2020-04-23 14:27 ip:64.207.247.119
    //
    // Signed: email applicant 2020-04-23 14:27 johndoe@gmail.com
    // agent 2020-04-25 09:33 ip:65.201.13.199
    //
    // Signed: self-enroll applicant 2020-04-23 14:27 ip:64.207.247.119
    // agent 2020-04-25 09:33 ip:65.201.13.199
    getAgentSignature() {
      return this._getSignature(SIG_TYPE_AGENT);
    },
    getApplicantSignature() {
      return this._getSignature(SIG_TYPE_APPLICANT);
    },

    /**
     *
     * @returns {string|null}
     */
    getApplicantSignatureDate() {
      let datetime = '';
      if (siteConfig.ffEnableExternalUnderwriting) {

        // if uw is submitted, use that as the "enroll date" (or "application date")
        if (this.enrollmentDetails.is_uw_submitted && this.enrollmentDetails.uw_submitted_at) {
          datetime = formats.utcToLocalDateTime(this.enrollmentDetails.uw_submitted_at);
          console.debug("applicantSignatureDetails: uw_submitted_at", datetime);
        } else { // fallback to applicant signature
          const appSignature = this.getApplicantSignature();
          if (appSignature) {
            datetime = formats.localToDateTime(appSignature.created_at);
            console.debug("applicantSignatureDetails: appSignature.created_at", datetime);
          } else {
            console.debug("applicantSignatureDetails: no appSignature date");
          }
        }
      } else { // if no external underwriting, use the applicant's signature date
        const appSignature = this.getApplicantSignature();
        if (appSignature) {
          datetime = formats.localToDateTime(appSignature.created_at);
        }
      }
      return datetime;
    },

    /**
     * @param recording
     * @returns {?Promise<Blob>}
     */
    retrieveRecordingDataForPlayback(recording) {
      if (!recording || !recording.mp3_url) {
        this.errors = ['No recording found.'];
        return null;
      }
      return api.getRemoteDownloadBlob(recording.mp3_url)
        .then((responseBlob) => {
          // note that the response mime type must be 'audio/mpeg' for the audio player to work
          if (!responseBlob || responseBlob.type !== 'audio/mpeg') {
            console.log("Recording file may still be processing.  We should try again later.  Returning recording obj unaltered", { recording, responseBlob});
            return recording;
          }

          recording.localPlaybackUrl = URL.createObjectURL(responseBlob);
          return recording;
        })
    },

    /**
     * @param recording
     * @returns {Promise<Blob>}
     */
    downloadRecording(recording) {
      if (!recording || !recording.mp3_url) {
        this.errors = ['No recording found.'];
        return;
      }

      this.isLoadingDetails = true;
      return api.getRemoteDownloadBlob(recording.mp3_url)
        .then((response_blob) => {
          const url = URL.createObjectURL(response_blob);
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';

          // remove file extension from enrollment_filename
          const filenameNoExt = this.enrollmentDetails.enrollment_filename.replace(/\.[^/.]+$/, "");

          link.download = `${filenameNoExt}_sid${recording.sid}-${Date.now()}.mp3`;
          link.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoadingDetails = false;
        });
      // use fetch
      // return fetch(blobSourceUrl, {
      //   "body": null,
      //   "method": "GET",
      // })
      //   // we want an object URL from the file blob
      //   .then(response => response.blob())
      //   .then(blob => {
      //     console.log(`Got the blob! ${blob.size} bytes`);
      //     // create a new URL for the blob
      //     const objUrl = URL.createObjectURL(blob);
      //     return objUrl;
      //   })
      //   .catch(error => {
      //     console.error(`Error getting the blob: ${error}`);
      //   });
    },
  },

  computed: {
    isExpired() {
      return this.enrollmentDetails && (this.enrollmentDetails.status === enrollmentStatuses.STATUS_EXPIRED);
    },
    isWaived() {
      return this.enrollmentDetails && (this.enrollmentDetails.status === enrollmentStatuses.STATUS_WAIVED);
    },
    isPaused() {
      return this.enrollmentDetails && (this.enrollmentDetails.status === enrollmentStatuses.STATUS_PAUSED);
    },
    showSendRemoteSignatureEmail() {
      if (this.enrollmentDetails.status) {
        return this.enrollmentDetails.status.toLowerCase() === enrollmentStatuses.STATUS_PENDING_APPLICANT_SIGNATURE.toLowerCase();
      }
      return false;
    },
    userCanDeleteEnrollment() {
      return this.userPermissions.canDeleteEnrollment();
    },

    hasSplitAgents() {
      return this.splitAgents.length > 0;
    },

    showEnrollingAgentSplit() {
      return !!(this.enrollmentDetails.case_agent_splits_enabled || this.hasSplitAgents || this.showEditSplitAgents);
    },

    canAddOrEditSplitAgents() {
      return !!(this.enrollmentDetails.delivery_required
        && this.enrollmentDetails.case_agent_splits_enabled
        && this.enrollmentDetails.status === enrollmentStatuses.STATUS_ENROLLED);
    },

    canAddSplitAgents() {
      return this.splitAgents.length < SPLIT_AGENT_MAX;
    },

    hasExistingSplitAgents() {
      for (let i = 0; i < SPLIT_AGENT_KEYS_PREFIXES.length; i++) {
        let agent_key = SPLIT_AGENT_KEYS_PREFIXES[i] + "_id";
        if (this.enrollmentDetails[agent_key]) {
          return true;
        }
      }
    },

    canCreateNewEnrollment() {
      return !this.isPaused;
    },

    enrollmentTimestampText() {
      if (!this.enrollmentDetails) {
        return "";
      }
      const enrollVerb = terminology.formatEnrollment({isVerb: true, isPastTense: true, isTitle: true});
      return (!this.isPaused) ? `${enrollVerb} Date` : "Paused Date";
    },

    enrollmentTimestamp() {
      if (!this.enrollmentDetails) {
        return "";
      }

      if (this.isPaused) {
        return this.formatDateTime(this.enrollmentDetails.enroll_date_created, '');
      }

      return this.getApplicantSignatureDate();
    },

    applicantSignatureDetails() {
      // @TODO limit what is displayed by user
      let applicant_sig = this.getApplicantSignature();
      if (!applicant_sig || applicant_sig.status !== SIG_STATUS_SIGNED) {
        return '';
      }
      let method = applicant_sig.method;
      let ip = applicant_sig.ip_address;
      let applicant_phone_number = applicant_sig.applicant_phone_number;

      let datetime = this.getApplicantSignatureDate();

      let email = this.enrollmentDetails.remote_signature_email;
      let call_center_email = this.enrollmentDetails.call_center_email;

      let sig_str = "";
      if (method === SIG_METHOD_INPERSON) {
        sig_str += "in-person "
        sig_str += datetime + " ";
        if (ip && this.userPermissions.isHomeOfficeAdmin()) {
          sig_str += " ip:" + ip;
        }
      } else if (method === SIG_METHOD_REMOTE) {
        sig_str += "email applicant "
        sig_str += datetime + " ";
        if (this.userPermissions.isHomeOfficeAdmin()) {
          sig_str += email;
        }
      } else if (method === SIG_METHOD_SELFENROLL) {
        sig_str += "self-enroll applicant "
        sig_str += datetime + " ";
        if (ip && this.userPermissions.isHomeOfficeAdmin()) {
          sig_str += " ip:" + ip;
        }
      } else if (method === SIG_METHOD_VOICE) {
        sig_str += "voice signed "
        sig_str += datetime + " ";

        if (call_center_email && this.userPermissions.isHomeOfficeAdmin()) {
          sig_str += call_center_email;
        }
      } else if (method === SIG_METHOD_AUDIOENROLL) {
        sig_str += "audio-enrolled "
        sig_str += datetime
        if (applicant_phone_number) {
          sig_str += " " + applicant_phone_number
        }
      }

      return sig_str;
    },

    agentSignatureDetails() {
      let agent_sig = this.getAgentSignature();
      if (!agent_sig || agent_sig.status !== SIG_STATUS_SIGNED) {
        return '';
      }
      let applicant_sig = this._getSignature(SIG_TYPE_APPLICANT);
      if (!applicant_sig || applicant_sig.method === SIG_METHOD_INPERSON) {
        return '';
      }

      let ip = agent_sig.ip_address;
      let datetime = formats.utcToLocalDateTime(agent_sig.updated_at);

      let sig_str = "agent ";
      sig_str += datetime;
      if (ip && this.userPermissions.isHomeOfficeAdmin()) {
        sig_str += " ip:" + ip;
      }

      return sig_str;
    }
  },
  filters: {
    enrollmentStatus(val) {
      return formats.enrollmentStatus(val);
    }
  },
  created() {
    this.getEnrollmentDetails(this.$route.params.id)
      .then(() => {
        return this.getCaseUsers(this.enrollmentDetails.case_id);
      })
      .then(() => {
        return this.searchCases();
      })      
      .then(() => {
        return this.getCaseUsersAccess(this.enrollmentDetails.case_id);
      })
      .then(() => {
        if (siteConfig.ffEnableEnrollmentSessionConferences) {
          return this.getRecordings(this.$route.params.id);
        }
      })
      .then(() => {
        this.loadAgentSplitsFromEnrollmentDetails();
        this.isLoading = false;
      });
  },
}
</script>

<style scoped>
.recordingPlaybackWidget {
    width: 100%;
}
</style>