<template>
  <div class="mb-4">
    <b-form-datalist :id="'relationship-list-'+coverageContext.id"
                     :options="siteConfig.beneficiaryRelationshipOptions"></b-form-datalist>

    <div v-for="(beneficiary, idx) in beneficiaries" :key="idx">
      <b-card class="mb-2">
        <b-row>
          <b-col>
            <b-form-group :label="beneficiaryClassification + ' Beneficiary Name'" s
                          invalid-feedback="Beneficiary Name is required"

            >
              <b-row v-if="isSingleNameRelationship(beneficiary.relationship)" no-gutters>
                <b-col>
                  <b-form-input
                    size="sm"
                    v-model="beneficiary.name"
                    @change="handleBeneficiaryChanged"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row v-else no-gutters> <!-- show three name fields -->
                  <b-col md="4" class="pb-1">
                    <b-form-input
                      class="mr-1"
                      size="sm"
                      v-model="beneficiary.first_name"
                      placeholder="First Name"
                      required></b-form-input>
                  </b-col>
                  <b-col md="3" class="pb-1 px-sm-1">
                    <b-form-input
                      size="sm"
                      v-model="beneficiary.middle_name"
                      placeholder="Middle Name"
                    ></b-form-input>
                  </b-col>
                  <b-col md="5">
                    <b-form-input
                      class="pb-1"
                      size="sm"
                      v-model="beneficiary.last_name"
                      placeholder="Last Name"
                      required></b-form-input>
                  </b-col>
              </b-row>

            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group :label="'Relationship to ' + coverageContext.applicant.getName()"
                          invalid-feedback="Beneficiary Relationship is required"
            >
              <b-form-input
                size="sm"
                v-model="beneficiary.relationship"
                @change="handleBeneficiaryChanged"
                required
                :list="'relationship-list-'+coverageContext.id"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="includeSSN === true" lg="2">
            <b-form-group label="SSN"
                          invalid-feedback="SSN is required"
            >
              <b-form-input
                size="sm"
                v-mask="'000-00-0000'"
                :trim="true"
                v-model="beneficiary.ssn"
                @change="handleBeneficiaryChanged"
                required
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="includeDOB === true" lg="2">
            <b-form-group
              label="Date of Birth"
              invalid-feedback="Date of Birth is required"
            >
              <b-form-input
                v-if="isPersonRelationship(beneficiary.relationship)"
                size="sm"
                v-model="beneficiary.dob"
                type="date"
                @change="handleBeneficiaryChanged"
                :required="requireDOB"
                autocomplete="off"
              ></b-form-input>
              <span class="text-muted" v-else>n/a</span>
            </b-form-group>
          </b-col>

          <b-col lg="2">
            <b-form-group label="Percentage"
                          :state="getPercentageValidationState(beneficiary)"
                          :invalid-feedback="getInvalidPercentageFeedback(beneficiary)">
                <b-form-input
                  size="sm"
                  type='number'
                  min="1"
                  max="100"
                  v-model="beneficiary.percentage"
                  @change="handleBeneficiaryPercentageChanged"
                  :disabled="beneficiary.isDisabled"
                  :state="getPercentageValidationState(beneficiary)"
                  required
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col align-self="center" lg="1">

            <b-form-group>
              <template #label> <!-- this puts a blank label in the form group for consistent spacing -->
                &nbsp;
              </template>
              <b-button size="sm" v-show="beneficiaries.length > 1 || optional"
                        @click="removeBeneficiary(beneficiary)">
                <font-awesome-icon icon="times"></font-awesome-icon>
              </b-button>

            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-row>
      <b-col cols="6">
        <b-dropdown variant="success">
          <span slot="button-content">
            Add {{ beneficiaryClassification }} Beneficiary
          </span>
          <b-dropdown-item @click="addNewBeneficiary">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            Add new
          </b-dropdown-item>

          <b-dropdown-item
            v-for="(beneficiaryOption, idx) in beneficiaryOptions"
            :key="beneficiaryOption.relationship+idx"
            @click="addBeneficiary(beneficiaryOption)">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            Add {{ beneficiaryOption.first_name }} {{ beneficiaryOption.middle_name }} {{ beneficiaryOption.last_name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="6">
        <b-alert :show="showBeneficiaryRelationshipDisclaimer" fade variant="warning" v-text="beneficiaryRelationshipDisclaimerText"></b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import bus from "./messages";
import {ApplicantTypeChild, ApplicantTypeEmployee, ApplicantTypeSpouse} from "./models";

let beneficiariesEditor = {
  name: "BeneficiariesEditor",
  props: [
    "name",
    "coverageContext",
    "optional",
    "coverageContext",
    "applicants",
    "globalBeneficiaries",
    "beneficiaryClassification",
    "previouslyEnteredBeneficiaries",
    "siteConfig",
    "includeSSN",
    "includeDOB",
    "requireDOB"
  ],
  data: function () {

    const initialData = this.coverageContext.getValue(this.name);
    let beneficiaries = [];
    if (initialData !== null) {
      initialData.forEach((bene) => {
        beneficiaries.push({
          name: bene.name,

          first_name: bene.first_name,
          middle_name: bene.middle_name,
          last_name: bene.last_name,

          relationship: bene.relationship,
          ssn: bene.ssn,
          dob: bene.dob,
          percentage: bene.percentage,
          disabled: false,
        });
      });
    }

    return {
      beneficiaries: beneficiaries,
      validated: false,
    }
  },
  created() {
    if (!this.optional && this.beneficiaries.length === 0) {
      this.addNewBeneficiary();
    }
    this.computeLastRowPercent();

    bus.$on("validate", () => {
      this.validated = true;
    })
  },
  methods: {
    isSingleNameRelationship(relationship) {
      console.log("The relationship is", relationship)
      if (relationship && this.siteConfig && this.siteConfig.beneficiarySingleNameRelationshipOptions) {
        return this.siteConfig.beneficiarySingleNameRelationshipOptions.includes(relationship)
      }
      return false;
    },
    addNewBeneficiary() {
      const beneficiary = {
        name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        relationship: "",
        ssn: "",
        percentage: 0,
        isDisabled: false,
      };

      // Add to the start of the list
      this.beneficiaries.unshift(beneficiary);

      this.computeLastRowPercent();

      this.emitValue();
    },
    addBeneficiary(beneficiaryOption) {
      // If only one beneficiary, and it is blank, we replace that beneficiary.
      if (this.beneficiaries.length === 1 && this.beneficiaries[0].name === "" && this.beneficiaries[0].relationship === "" && this.beneficiaries[0].ssn === "") {
        this.beneficiaries.splice(0, 1);
      }

      // Add to the start of the list
      this.beneficiaries.unshift({
        name: beneficiaryOption.name,
        first_name: beneficiaryOption.first_name,
        middle_name: beneficiaryOption.middle_name,
        last_name: beneficiaryOption.last_name,
        relationship: beneficiaryOption.relationship,
        ssn: beneficiaryOption.ssn,
        percentage: 0,
        isDisabled: false,
      });

      this.computeLastRowPercent();
      this.emitValue();
    },
    removeBeneficiary(beneficiary) {
      const removeIndex = this.beneficiaries.indexOf(beneficiary);
      this.beneficiaries.splice(removeIndex, 1);

      this.computeLastRowPercent();

      this.emitValue();
    },

    /**
     * Take a beneficiary object and normalize the name properties so that we can use the same
     * structure for both single and multi name beneficiaries
     * @param {object} beneficiary
     * @returns {object} the beneficiary object with normalized name properties
     * @private
     */
    _normalizeNamePropertiesOnBeneficiary(beneficiary) {
      if (this.isSingleNameRelationship(beneficiary.relationship)) {
        console.debug(`Beneficiary relationship ${beneficiary.relationship} is a single name relationship, so we are assigning .name to .first_name`);
        beneficiary.first_name = beneficiary.name;
        beneficiary.middle_name = "";
        beneficiary.last_name = "";
      } else {
        if (!beneficiary.first_name && !beneficiary.last_name) {
          // if we don't have first and last name, then we need to
          // split the .name into first and last name
          console.debug(`Beneficiary relationship ${beneficiary.relationship} is a multi name relationship, so we are assigning .first_name and .last_name to .name`);
          const nameParts = beneficiary.name.split(" ");
          if (nameParts.length > 0) {
            beneficiary.first_name = nameParts[0].trim();
          }
          if (nameParts.length > 1) {
            beneficiary.last_name = nameParts[nameParts.length - 1].trim();
          }
          if (nameParts.length > 2) {
            beneficiary.middle_name = nameParts.slice(1, nameParts.length - 1).join(" ").trim();
          }
        } else {
          console.debug(`Beneficiary relationship ${beneficiary.relationship} is a multi name relationship, so we are assigning .name to .first_name + .middle_name + .last_name`);
          beneficiary.name = beneficiary.first_name.trim() + (" " + beneficiary.middle_name).trimEnd() + (" " + beneficiary.last_name).trimEnd();
        }
      }
      return beneficiary;
    },

    handleBeneficiaryChanged() {
      // normalize the name properties on all beneficiaries, because we are about to emit the value
      this.beneficiaries.forEach(beneficiary => {
        this._normalizeNamePropertiesOnBeneficiary(beneficiary);
      });

      this.emitValue();
    },
    handleBeneficiaryPercentageChanged() {
      this.computeLastRowPercent();
      this.emitValue();
    },
    serializeBeneficiaries() {


      return this.beneficiaries.map((beneficiary) => {
        // when we serialize, we will also update the name of the beneficiary if needed
        // from first/middle/last to name
        if (this.isSingleNameRelationship(beneficiary.relationship) && beneficiary.name === "" && beneficiary.first_name || beneficiary.middle_name || beneficiary.last_name) {
          beneficiary.name = (beneficiary.first_name + " " + beneficiary.last_name).trim();
        }

        return {
          name: beneficiary.name,
          first_name: beneficiary.first_name,
          middle_name: beneficiary.middle_name,
          last_name: beneficiary.last_name,
          relationship: beneficiary.relationship,
          ssn: beneficiary.ssn,
          percentage: parseInt(beneficiary.percentage),
          dob: beneficiary.dob
        };
      });
    },
    emitValue() {

      bus.$emit("coverage-data-changed", {
        name: this.name,
        coverage: this.coverageContext,
        value: this.serializeBeneficiaries()
      });

      // Update global registry of beneficiaries we've added
      this.beneficiaries.forEach(beneficiary => {
        if (!beneficiary) {
          console.debug("Not adding beneficiary because it is falsy", beneficiary)
          return;
        }

        if (!beneficiary.relationship) {
          console.debug("Not adding beneficiary because it has no relationship", beneficiary)
          return;
        }

        if (!this.isSingleNameRelationship(beneficiary.relationship)) {
          if (!beneficiary.last_name) {
            console.debug("Not adding beneficiary because it has no last name and it is not a Single Name relationship", beneficiary)
            return;
          }
        }

        bus.$emit("beneficiary-added", {
          name: beneficiary.name,
          first_name: beneficiary.first_name,
          middle_name: beneficiary.middle_name,
          last_name: beneficiary.last_name,
          relationship: beneficiary.relationship,
          ssn: beneficiary.ssn,
        });
      });
    },
    computeLastRowPercent() {
      const lastBeneficiaryRow = this.beneficiaries.pop();
      if (lastBeneficiaryRow === undefined) {
        return;
      }

      // Sum up the other rows
      let sum = 0;
      this.beneficiaries.forEach((bene) => {
        const percentage = parseInt(bene.percentage);
        if (!isNaN(percentage)) {
          sum += percentage;
        }
      });

      // Show the remainder in the last row
      if (sum >= 100) {
        lastBeneficiaryRow.percentage = 0;
      } else {
        lastBeneficiaryRow.percentage = 100 - sum;
      }

      // Put the last row back on
      this.beneficiaries.push(lastBeneficiaryRow);

      // Disable the last row only
      this.beneficiaries.forEach(bene => {
        bene.isDisabled = false;
      });
      this.beneficiaries[this.beneficiaries.length - 1].isDisabled = true;
    },
    getPercentageValidationState(beneficiary) {

      if (parseInt(beneficiary.percentage) === 0) {
        return false;
      }

      // If any beneficiary in this set has a percentage of 0, return invalid
      let hasZero = false;
      this.beneficiaries.forEach((bene) => {
        if (parseInt(bene.percentage) === 0) {
          hasZero = true;
        }
      });
      if (hasZero) {
        return false;
      }

      if (!this.validated) {
        return null;
      }
      return true;
    },
    getInvalidPercentageFeedback(beneficiary) {
      const percentage = parseInt(beneficiary.percentage);
      const isValidInt = !isNaN(percentage);
      const isValidPercent = (isValidInt && percentage >= 1 && percentage <= 100);

      if (beneficiary.percentage === "" || !isValidInt) {
        return "Beneficiary Percentage is required"
      } else if (isValidInt && !isValidPercent) {
        return "Percent must be between 1 and 100";
      } else {
        return "";
      }
    },

    /**
     * Returns true if the relationship described indicates the beneficiary is a human being (and not Estate)
     * @param {string} relationship
     * @returns {boolean}
     */
    isPersonRelationship(relationship) {
      console.log("The reslationship is", relationship)
      if (!relationship) {
        return false;
      }

      // Estate is not a person. Right now this is the only case where we don't want to show the DOB field - EF 2022-12-30
      return relationship.toLowerCase() !== "estate";
    }
  },
  computed: {
    showBeneficiaryRelationshipDisclaimer() {
      return !!this.beneficiaryRelationshipDisclaimerText;
    },
    beneficiaryRelationshipDisclaimerText() {
      for (let x = 0; x < this.beneficiaries.length; x++) {
        let thisRelationship = this.beneficiaries[x].relationship;
        if (thisRelationship && this.siteConfig.beneficiaryRelationshipDisclaimers[thisRelationship]) {
          return this.siteConfig.beneficiaryRelationshipDisclaimers[thisRelationship];
        }
      }
    },
    totalPercent() {
      return this.beneficiaries.reduce((sum, beneficiary) => parseInt(beneficiary.percentage), 0);
    },
    beneficiaryOptions() {

      let options = [];

      const thisApplicant = this.coverageContext.applicant;

      let emp = this.applicants.getFirstApplicantByType(ApplicantTypeEmployee);
      if (emp && thisApplicant.getDefaultRelationshipTo(emp) !== "Self") {
        options.push({
          name: emp.getFullName(),
          first_name: emp.getFirstName(),
          middle_name: emp.getMiddleName(),
          last_name: emp.getLastName(),
          relationship: thisApplicant.getDefaultRelationshipTo(emp),
        });
      }

      let sp = this.applicants.getFirstApplicantByType(ApplicantTypeSpouse);
      if (sp && thisApplicant.getDefaultRelationshipTo(sp) !== "Self") {
        options.push({
          name: sp.getFullName(),
          first_name: sp.getFirstName(),
          middle_name: sp.getMiddleName(),
          last_name: sp.getLastName(),
          relationship: thisApplicant.getDefaultRelationshipTo(sp),
        });
      }

      let children = this.applicants.getApplicantsByType(ApplicantTypeChild);
      children.forEach(child => {
        if (thisApplicant.getDefaultRelationshipTo(child) !== "Self") {
          options.push({
            name: child.getFullName(),
            first_name: child.getFirstName(),
            middle_name: child.getMiddleName(),
            last_name: child.getLastName(),
            relationship: thisApplicant.getDefaultRelationshipTo(child),
          });
        }
      });

      if (this.globalBeneficiaries) {
        this.globalBeneficiaries.forEach((beneficiary) => {
          options.push(this._normalizeNamePropertiesOnBeneficiary({
            name: beneficiary.name,
            first_name: beneficiary.first_name,
            middle_name: beneficiary.middle_name,
            last_name: beneficiary.last_name,
            relationship: beneficiary.relationship,
          }));
        });
      }

      // Don't show options with the same first/middle/last names, or options that are
      //  currently already included in the list,
      //  and doesn't match the current applicant by name
      let dedupedOptions = [];
      options.forEach(option => {
        let existingInOptions = dedupedOptions.find(option2 => {
          return (
            option.first_name === option2.first_name &&
            option.middle_name === option2.middle_name &&
            option.last_name === option2.last_name
          );
        });
        let existingInSelected = this.beneficiaries.find(b => {
          return (
            option.first_name === b.first_name &&
            option.middle_name === b.middle_name &&
            option.last_name === b.last_name
          );
        });

        if (existingInOptions === undefined && existingInSelected === undefined && thisApplicant.getFullName() !== option.name) {
          dedupedOptions.push(option);
        }
      });

      // Filter out "Estate" and "" from this list (gives us everything back BUT "..." and "Estate") -- EF 2023-02-06
      let filtered_options = dedupedOptions.filter(o => o.first_name !== 'Estate' && o.first_name !== '');

      // Add in Estate at the end
      filtered_options.push({
        name: "Estate",
        first_name: "Estate",
        middle_name: "",
        last_name: "",
        relationship: "Estate",
      });

      return filtered_options;
    }
  },
  watch: {}
};

export default beneficiariesEditor;
</script>
