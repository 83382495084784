import manageUserForm from './manage-user-form.html'
import Api from "./api";
import draggable from 'vuedraggable'
import formats from './models/formats'
import colorDefaults from './models/color-defaults'

export default {
  template: manageUserForm,
  props: {
    userPermissions: { type: Object },
    userForm: { type: Object },
    tags: { type: Array },
    title: { type: String }
  },
  components: {
    draggable
  },
  data: function () {
    return {
      homeOfficeAdmin: false,
      caseAdmin: false,
      userAdmin: false,
      reportsAdmin: false,
      userIsHomeOfficeAdmin: false,
      tagValue: '',
      newTagName: '',
      selectedTags: [],
      userErrors: [],
      tagCreateSuccess: false,
      updateUserSuccess: false,
      sendInviteSuccess: false,
      createAPIUserSuccess: false,
      sendResetPasswordSuccess: false,
      setPasswordSuccess: false,
      colorDefaults: colorDefaults,
      isApiUser: false,

      showingSetPasswordModal: false,
      isUpdatingPassword: false,
      updatePasswordErrors: [],
      newPassword: '',
      newPasswordConfirmed: '',
      showPasswords: false,
      userRoles:{
        HomeOfficeAdmin: false,
        UserAdmin: false,
      },
      isRoleUpdated: false
    }
  },
  methods: {
    createUser(user) {
      this.clearAlerts();
      return Api.createUser(user);
    },
    createUserWithTagsAndRoles(user) {
      this.userErrors = [];
      this.createUser(user)
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.userErrors = response.errors;
        } else {
          this.addRolesAndTagsToUser(response);
          if (this.isApiUser) { // add a promise for user activation
            this.toggleUserActivation(true);
          }
        }
      });
    },
    addRolesAndTagsToUser(user) {
      let rolesPromise = this.addRolesToUser(user.id);
      let tagsPromise = this.addTagsToUser(user.id);
      Promise.all([tagsPromise, rolesPromise])
      .then(responses => {
        responses.forEach(response => {
          if (response.errors && response.errors.length > 0) {
            this.userErrors = this.userErrors.concat(response.errors);
          }
        });

        if(this.userErrors.length === 0) {
          this.finishCreateUser(user);
        }
      });
    },
    finishCreateUser(user) {
      if(this.userPermissions.canSendUserInvite() && !this.isApiUser) { // don't send invitation for API users
        this.sendInvitation(user.id)
          .then(() => {
            this.$emit('user-complete-listener');
          });
      } else {
        if (this.isApiUser) {
          this.createAPIUserSuccess = true;
        }
        this.$emit('user-complete-listener');
      }
    },
    updateUser(user) {
      this.clearAlerts();
      delete user.select;
      delete user._rowVariant;
      return Api.updateUser(user);
    },
    updateUserAndTagsAndRoles(user) {
      this.userErrors = [];
      let tagsPromise = this.addTagsToUser(user.id);
      let rolesPromise = this.addRolesToUser(user.id);
      let userPromise = this.updateUser(user);

      let promises = [tagsPromise, rolesPromise, userPromise]

      if (this.isApiUser) { // add a promise for user activation
        promises.push(this.toggleUserActivation(true));
      }

      Promise.all(promises)
      .then(responses => {
        //sending event to parent to get updated cases list
        this.$emit('userUpdated');
        if (this.isRoleUpdated) {
          this.$emit('roleUpdated', this.userRoles)
        }
        this.isRoleUpdated = false;
        
        responses.forEach(response => {
          if (response.errors && response.errors.length > 0) {
            this.userErrors = this.userErrors.concat(response.errors);
          }
        });

        if(this.userErrors.length === 0) {
          this.updateUserSuccess = true;
          this.$emit('user-complete-listener');
        }
      });
    },
    deleteUser(userId) {
      //Api.deleteUser(userId);
    },
    addTagsToUser(userId) {
      let tagIds = this.selectedTags.map(t => {return t.value});
      let data = {tag_ids:tagIds};
      return Api.addTagsToUser(userId, data);
    },
    addRolesToUser(userId) {
      let roles = [];
      if(this.isApiUser) {
        roles.push('APIUser')
      } else if(this.homeOfficeAdmin) {
        roles = ['HomeOfficeAdmin', 'CaseAdmin', 'UserAdmin', 'ReportAdmin'];
      } else {
        if (this.caseAdmin) {
          roles.push('CaseAdmin');
        }
        if (this.userAdmin) {
          roles.push('UserAdmin');
        }
        if (this.reportsAdmin) {
          roles.push('ReportAdmin');
        }
      }
      return Api.addRolesToUser(userId, {roles:roles});
    },
    mapTags(response) {
      return response.map(tag => {return {value:tag.id, text:tag.name}});
    },
    createTag(name) {
      this.clearAlerts();
      Api.createTag({name:name})
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.tagCreateSuccess = true;
          let newTag = this.mapTags([response])[0]
          this.tags.push(newTag);
          this.selectedTags.push(newTag);
          this.newTagName = '';
        }
      });
    },
    showSetPasswordModal() {
      this.updatePasswordErrors = [];
      this.newPassword = null;
      this.newPasswordConfirmed = null;
      this.showPasswords = false;
      this.showingSetPasswordModal = true;
    },
    hideSetPasswordModal() {
      this.showingSetPasswordModal = false;
    },
    toggleUserActivation(isActive) {
      this.clearAlerts();
      let userCopy = Object.assign({}, this.userForm);
      userCopy.status = isActive ? 'active' : 'deactivated';
      this.updateUser(userCopy)
      .then(() => {
        this.updateUserSuccess = true;
        this.userForm.status = isActive ? 'active' : 'deactivated';
      });
    },
    hasRole(user, role) {
      return user.roles.filter(r => r.name === role).length > 0;
    },
    closeSection() {
      this.$emit('close-user-listener');
    },
    sendInvitation(userId) {
      return Api.sendInvitation({user_id:userId});
    },
    resendInvitation(userId) {
      this.clearAlerts();
      this.sendInvitation(userId)
      .then(response => {
        this.sendInviteSuccess = true;
      });
    },
    resetPassword(username) {
      this.clearAlerts();
      Api.forgotPassword({username: username})
      .then(response => {
        this.sendResetPasswordSuccess = true;
      });
    },
    setUserPassword(user) {
      this.clearAlerts();
      this.updatePasswordErrors = [];
      this.isUpdatingPassword = true;
      return Api.updateUserPassword(user.id, this.newPassword, this.newPasswordConfirmed)
        .then((response)=>{
          if (response.errors && response.errors.length > 0) {
            this.updatePasswordErrors = response.errors;
          } else {
            this.setPasswordSuccess = true;
            this.hideSetPasswordModal();
          }
        })
        .catch(() => {
          this.updatePasswordErrors = [{'msg':'Problem communicating with server to set password.'}];
        })
        .finally(() => {
          this.isUpdatingPassword = false;
        });
    },
    clearAlerts() {
      this.tagCreateSuccess = false;
      this.updateUserSuccess = false;
      this.sendInviteSuccess = false;
      this.createAPIUserSuccess = false;
      this.sendResetPasswordSuccess = false;
      this.setPasswordSuccess = false;
    },
    isDirty(oldValue, newValue) {
      return oldValue != newValue;
    }
  },
  computed: {
    userCanSetUserRoleToHOA() {
      return this.userPermissions.canSetUserRoleToHomeOffice();
    },
    userCanSetUserRole() {
      return this.userPermissions.canSetUserRole();
    },
    userCanCreateTag() {
      return this.userPermissions.canCreateTag();
    },
    userCanSetUserTags() {
      return this.userPermissions.canSetUserTags();
    },
    userCanChangeUser() {
      return this.userPermissions.canChangeUser();
    },
    userCanSendUserInvitationEmail() {
      return this.userPermissions.canSendUserInvite();
    },
    userCanSendUserResetPasswordEmail() {
      return this.userPermissions.canSendUserResetPasswordEmail();
    }
  },
  watch: {
    isApiUser: function() {
      if (this.isApiUser) {
        this.homeOfficeAdmin = !this.isApiUser;
        this.caseAdmin = !this.isApiUser;
        this.userAdmin = !this.isApiUser;
        this.reportsAdmin = !this.isApiUser;
      }
    },
    homeOfficeAdmin: function(newValue, oldValue) {   
      if (this.isDirty(oldValue, newValue)) {
        this.isRoleUpdated = true;
        this.userRoles.HomeOfficeAdmin = newValue
      }   
      this.caseAdmin = this.homeOfficeAdmin;
      this.userAdmin = this.homeOfficeAdmin;
      this.reportsAdmin = this.homeOfficeAdmin;
    },
    userAdmin: function(newValue, oldValue) {
      if (this.isDirty(oldValue, newValue)) {
        this.isRoleUpdated = true;
        this.userRoles.UserAdmin = newValue
      }
      this.userForm.pref_receive_new_user_notifications = this.userAdmin;
    }
  },
  created() {
    if(this.userForm.id) {

      // copy the val here to use below
      const local_pref_receive_new_user_notifications = this.userForm.pref_receive_new_user_notifications;

      this.homeOfficeAdmin = this.hasRole(this.userForm, 'HomeOfficeAdmin');
      this.caseAdmin = this.hasRole(this.userForm, 'CaseAdmin');
      this.userAdmin = this.hasRole(this.userForm, 'UserAdmin');
      this.$nextTick(function() {
        // set this here so it overrides the watch on userAdmin above. Only need to do this on component creation
        this.userForm.pref_receive_new_user_notifications = local_pref_receive_new_user_notifications;
      });
      this.reportsAdmin = this.hasRole(this.userForm, 'ReportAdmin');
      this.selectedTags = this.mapTags(this.userForm.tags);
      this.userForm.last_login = formats.utcToDateTime(this.userForm.last_login);

      this.isApiUser = this.hasRole(this.userForm, 'APIUser');
    }
  }
}
