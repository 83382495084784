<template>
    <div class="card mt-2">
        <div class="card-header bg-secondary text-white h5">Cases assigned (directly or via tags)</div>
        <div class="card-body">
            <div v-if="assignedCases.length">
                <b-table striped hover :items="assignedCases" :fields="fields">
                    <template #cell(select)="row">
                        <div
                            role="button"
                            v-if="!isCaseTagged(row.item.tags)"
                            @click="removeAssignedCase(row.item)"
                        >
                            <font-awesome-icon icon="check-square"></font-awesome-icon>
                        </div>
                        <div v-else class="text-secondary">
                            <font-awesome-icon icon="minus-square"></font-awesome-icon>
                        </div>
                    </template>

                    <div
                        class="sort-component"
                        v-for="field in fields"
                        :slot="'HEAD_'+field.key"
                        v-if="field.label"
                    >
                        <div class="sort-label">{{field.label}}</div>
                    </div>
                </b-table>

                <div class="row mx-3 pt-3">
                    <b-pagination
                        size="sm"
                        v-model="currentPage"
                        :total-rows="totalCasesCount"
                        :per-page="rowsPerPage"
                        aria-controls="my-table"
                    ></b-pagination>

                    <b-form-select
                        class="col-0 ml-2 pag-row-number"
                        v-model="rowsPerPage"
                        :options="[
            25,
            50,
            100
          ]"
                    ></b-form-select>
                </div>
            </div>
            <div v-else>No case assigned</div>
        </div>
    </div>
</template>
<script>
import Api from "./api.js";
export default {
    props: ["bus"],
    data() {
        return {
            userId: null,
            rowsPerPage: 25,
            currentPage: 1,
            fields: [
                {
                    key: "select",
                    label: "",
                    class: "select-column"
                },
                {
                    key: "name",
                    sortable: false
                },
                {
                    key: "tags",
                    sortable: false
                },
                {
                    key: "status"
                }
            ],
            assignedCases: [],
            totalCasesCount: 0
        };
    },
    created() {
        this.userId = this.$route.params.id;
        this.bus.$on("userUpdated", () => {
            this.fetchAssignedCases();
        });
    },
    mounted() {
        this.fetchAssignedCases();
    },
    methods: {
        fetchAssignedCases() {
            const filters = {
                orderBy: "name",
                orderDirection: "asc",
                currentPage: this.currentPage,
                rowsPerPage: this.rowsPerPage
            };

            Api.assignedCases(this.userId, filters)
                .then(({ cases: data, totalResultsCount: rows }) => {
                    this.assignedCases = data;
                    this.totalCasesCount = rows;
                })
                .catch(error => {
                    this.$bvToast.toast("Something went wrong!", {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 3000,
                        headerClass: "d-none",
                        toaster: "b-toaster-bottom-right"
                    });
                });
        },
        isCaseTagged(tag) {
            return !!tag;
        },
        async removeAssignedCase(caseToBeRemoved) {
            const confirm = await this.$bvModal.msgBoxConfirm(
                "Are you sure to unassigned this case",
                {
                    title: "Please Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    centered: true
                }
            );

            if (!confirm) {
                return;
            }

            Api.deleteAssignedCase(this.userId, caseToBeRemoved.id)
                .then(data => {
                    if (data.success) {
                        this.$bvToast.toast("Case removed!", {
                            title: "Success",
                            variant: "success",
                            autoHideDelay: 3000,
                            headerClass: "d-none",
                            toaster: "b-toaster-bottom-right"
                        });
                    } else {
                        this.$bvToast.toast("Case can't be removed!", {
                            title: "Error",
                            variant: "danger",
                            autoHideDelay: 3000,
                            headerClass: "d-none",
                            toaster: "b-toaster-bottom-right"
                        });
                    }
                    this.fetchAssignedCases();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    watch: {
        currentPage(val) {
            this.fetchAssignedCases();
        },
        rowsPerPage(val) {
            this.fetchAssignedCases();
        }
    }
};
</script>