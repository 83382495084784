<template>
  <b-modal id="view-pdf-modal" title="App Form" size="xl" :busy="isLoading" scrollable centered ok-only
           :visible="visible"
           @hidden="$emit('hidden')"
  >
    <b-overlay :show="isLoading">
      <div class="text-right mb-4">
        <b-btn @click="downloadPDF(enrollmentId)" variant="outline-primary">
          <font-awesome-icon icon="download"></font-awesome-icon>
          Download
        </b-btn>
      </div>


      <b-card :title="`Page ${ i }`" v-for="i in numPages" class="mb-4">
        <pdf ref="pdfComponent" :src="pdfSrc"
             :key="i"
             :page="i"
             style="display: block; width: 100%;"></pdf>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import api from "./api";
import pdf from 'vue-pdf';

export default {
  name: "PdfModal",
  components: {
    pdf
  },
  props: {
    enrollmentId: {
      type: Number,
      required: false
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      pdfSrc: null,
      numPages: 0,
      isLoading: false,
    }
  },
  created() {
  },
  watch: {
    visible: function (val) {
      console.debug("visible changed to", val);
      if (!!val && !!this.enrollmentId) {
        console.debug("loading enrollment pdf; enrollmentId is", this.enrollmentId);
        this.loadEnrollmentPDF(this.enrollmentId);
      }
    }
  },
  methods: {

    loadEnrollmentPDF(enrollmentId) {
      this.pdfSrc = null;
      this.numPages = 0;

      this.isLoading = true;

      return api.getDownloadBlob(
        `/api/enrollments/${enrollmentId}/pdf`,
        null,
      )
        .then(async (response_blob) => {

          this.pdfSrc = pdf.createLoadingTask(
            await response_blob.arrayBuffer().then(buffer => new Uint8Array(buffer))
          )
          this.pdfSrc.promise.then(pdf => {
            this.numPages = pdf.numPages;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    downloadPDF(enrollmentId) {
      this.isLoading = true;

      return api.getDownloadBlob(
        `/api/enrollments/${enrollmentId}/pdf`,
        null,
      )
        .then(async (response_blob) => {
          const url = window.URL.createObjectURL(response_blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `preview_enrollment_app_${enrollmentId}.pdf`;
          link.click();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
