<template>
  <div v-if="underwritingDetails && underwritingDetails.length > 0">

    <b-button
      size="sm"
      variant="outline-primary"
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="underwriting-collapse-4"
      @click="visible = !visible"
    >
      <span class="ml-2 mr-2">Underwriting Details</span>
      <font-awesome-icon :icon="['fa', 'caret-down']"></font-awesome-icon>
    </b-button>
    <b-collapse v-model="visible" id="underwriting-collapse-1" class="mt-4" appear>
      <h5>Decision Details</h5>
      <b-card-group deck>
        <b-card
          v-for="underwritingDetail in underwritingDetails"
          no-body>
<!-- removed header for now -->
<!--          :header="underwritingDetail.category_name"-->
<!--          header-bg-variant="secondary"-->
<!--          header-text-variant="light"-->
<!--        >-->
        <b-list-group flush>
            <b-list-group-item v-for="riskFactor in underwritingDetail.risk_factors">{{
                riskFactor
              }}
            </b-list-group-item>
            <b-badge v-if="underwritingDetails.policy_rating">{{ underwritingDetail.policy_rating }}</b-badge>
          </b-list-group>
        </b-card>
      </b-card-group>
    </b-collapse>
  </div>
</template>

<script>

export default {
  name: "UnderwritingDecisionDetails",
  props: {
    underwritingDetails: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      visible: false
    };
  }
}
</script>

<style scoped>

</style>
